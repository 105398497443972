import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { AuctionModel, AuctionModelService } from '../../model/auction.model';
import * as moment from 'moment';
import { AuctionStatus } from 'src/app/API.service';

@Component({
  selector: 'app-auctionstatusflow',
  templateUrl: './auctionstatusflow.component.html',
  styleUrls: ['./auctionstatusflow.component.scss']
})
export class AuctionstatusflowComponent implements OnInit {

  _STATUS_SETUP_AUCTION                        = "auctionsetup";
  _STATUS_POST_AUCTION_CALENDAR                = "postauctioncalendar";
  _STATUS_POST_AUCTION_SALE_INFO               = "postauctionsaleinfo";
  _STATUS_POST_AUCTION_LOTS                    = "postauctionlots";
  _STATUS_SETTLE_AUCTION                       = "settleauction";
  _STATUS_DIRECT_SETTLEMENT                    = "directsettlement";
  _STATUS_PARTIAL_PAYMENT                      = "partialpayment";
  _STATUS_START_AUCTION                        = "startauction";
  _STATUS_CLOSE_AUCTION                        = "closeauction";
  _STATUS_AUCTION_GENERATE_SALE_REPORT         = "generateauctionsalereport";
  _STATUS_AUCTION_GENERATE_SETTLEMENT_SHEET    = "generateauctionsettlementsheet";
  _STATUS_DELETE_AUCTION                       = "deleteauction";
  _STATUS_CHANGE_EXTENSION_INTERVAL            = "changeextensioninterval";

  _MILLISECONDS_PER_DAY                        = 86400000;

  @Output() messageEvent = new EventEmitter<any>();
  public extensionPeriodIntervals = AuctionModel.EXTENSION_PERIOD_INTERVALS;

  @ViewChild('apptimecounter') apptimecounter;

  constructor(public auctionModel: AuctionModelService) { }

  ngOnInit(): void {
  }

  onSetup(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_SETUP_AUCTION;
    this.messageEvent.emit(eventMsg);
  }

  onStartAuction(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_START_AUCTION;
    this.messageEvent.emit(eventMsg);
  }

  onDeleteAuction(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_DELETE_AUCTION;
    this.messageEvent.emit(eventMsg);
  }

  onCloseAuction(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_CLOSE_AUCTION;
    this.messageEvent.emit(eventMsg);
  }

  onGenerateSaleReport(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_AUCTION_GENERATE_SALE_REPORT;
    this.messageEvent.emit(eventMsg);
  }

  onGenerateSettlementSheet(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_AUCTION_GENERATE_SETTLEMENT_SHEET;
    this.messageEvent.emit(eventMsg);
  }

  onSettleAuction(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_SETTLE_AUCTION;
    this.messageEvent.emit(eventMsg);
  }

  onDirectSettlement(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_DIRECT_SETTLEMENT;
    this.messageEvent.emit(eventMsg);
  }

  onPartialPayment(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_PARTIAL_PAYMENT;
    this.messageEvent.emit(eventMsg);
  }

  onPostCalendar(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_POST_AUCTION_CALENDAR;
    this.messageEvent.emit(eventMsg);
  }

  onPostSaleInfo(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_POST_AUCTION_SALE_INFO;
    this.messageEvent.emit(eventMsg);
  }

  onPostLots(){
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_POST_AUCTION_LOTS;
    this.messageEvent.emit(eventMsg);
  }

  onExtensionIntervalChanged() {
    var eventMsg = {};
    eventMsg['message'] = this._STATUS_CHANGE_EXTENSION_INTERVAL;
    this.messageEvent.emit(eventMsg);
  }

  formatDate(dateStr: string) {
    if (!dateStr) return null;
    return moment(dateStr).format('MMM D, YYYY');
  }

  loadPageData() {
    this.apptimecounter.loadPageData();
  }

  async receiveMessage($event) {
    if ($event['message'] == 'auction-closed') {
      if ([AuctionStatus.LIVE, AuctionStatus.LOTS_POSTED, AuctionStatus.SALE_INFO_POSTED, AuctionStatus.CALENDAR_POSTED].includes(this.auctionModel.activeAuction.status)) {
        await this.auctionModel.activeAuction.updateStatus(AuctionStatus.CLOSED);
      }
    }
    else if ($event['message'] == 'auction-started') {
      if ([AuctionStatus.SALE_INFO_POSTED, AuctionStatus.CALENDAR_POSTED, AuctionStatus.LOTS_POSTED].includes(this.auctionModel.activeAuction.status)) {
        await this.auctionModel.activeAuction.updateStatus(AuctionStatus.LIVE);
      }
    }
  }
}
