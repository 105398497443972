<div class="container-fluid">

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Personal Details
        </div>
        <div class="col-md-6 right-justify center r-top">
            <button nbButton size="small" status="primary" (click)="onSave()" [disabled]="!pageDataValid()">SAVE</button>
        </div>
    </div> 
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            TITLE
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="nametitle" 
                   name="nametitle" 
                   placeholder="Title i.e. Mr., Mrs, Miss"
                   [(ngModel)]="user.nameTitle">            
        </div>
        <div class="col"></div>
    </div>
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            FIRST NAME *
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="givenname"
                   name="givenname"
                   placeholder="First Name"
                   required
                   [(ngModel)]="user.givenName">            
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            LAST NAME *
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="familyname"
                   name="familyname"
                   placeholder="Last Name"
                   required
                   [(ngModel)]="user.familyName">            
        </div>
        <div class="col"></div>
    </div>
    
    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            EMAIL ADDRESS *
        </div>
        <div class="col"></div>
    </div> 
    
    <ng-container *ngIf="!changeemail && isEdit()">
        <div class="row mb-3 ml-2">
            <div class="col-md-4 info-text">
                {{ user.email }}
            </div>
            <div class="col-md-1 right-justify">
                <button nbButton outline size="tiny" status="primary" (click)="changeEmail()">CHANGE EMAIL</button>
            </div>
            <div class="col"></div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="changeemail && isEdit()">
        <div class="row mb-3 ml-2">
            <div class="col-md-4">
                <input nbInput 
                       fullWidth
                       style="width:100%" 
                       type="email" 
                       id="email" 
                       name="email" 
                       placeholder="Email Address"
                       #emailentry="ngModel"
                       [ngClass]="{'inputError':!validEmail() && emailentry.touched}"
                       [(ngModel)]="emailAddress">  
                <div class="errorText" *ngIf="!validEmail() && emailentry.touched">Please provide a valid email address.</div>
            </div>
            <div class="col"></div>
        </div>
        <div class="row mb-3 ml-2">
            <div class="col-md-8">
                <button nbButton [nbSpinner]="processing" nbSpinnerStatus="control" outline size="tiny" status="primary" (click)="onUpdateEmail()" [disabled]="!validEmail()">UPDATE</button>
                <button class="ml-3" nbButton outline size="tiny" status="primary" (click)="cancelChangeEmail()">CANCEL</button>
            </div>
        </div>
    </ng-container>
    
    <ng-container *ngIf="!isEdit()">
        <div class="row mb-3 ml-2">
            <div class="col-md-4">
                <input nbInput 
                       fullWidth
                       style="width:100%" 
                       type="email" 
                       id="email" 
                       name="email" 
                       placeholder="Email Address"
                       #emailentry="ngModel"
                       [ngClass]="{'inputError':!validEmail() && emailentry.touched}"
                       [(ngModel)]="user.email">  
                <div class="errorText" *ngIf="!validEmail() && emailentry.touched">Please provide a valid email address.</div>
            </div>
            <div class="col"></div>
        </div>
    </ng-container>
    

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div> 
    
    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Contact Info
        </div>
    </div> 
    
    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            BUSINESS NAME
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-6">
            <input nbInput 
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="businessname"
                   name="businessname"
                   placeholder="Business Name"
                   [(ngModel)]="user.businessName">
        </div>
        <div class="col"></div>
    </div>
    
    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            STREET ADDRESS 1
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="streetaddressone" 
                   name="streetaddressone" 
                   placeholder="Street Address One"
                   [(ngModel)]="streetAddressOne">            
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            STREET ADDRESS 2
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="streetaddresstwo" 
                   name="streetaddresstwo" 
                   placeholder="Street Address Two"
                   [(ngModel)]="streetAddressTwo">            
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            CITY
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="city" 
                   name="city" 
                   placeholder="City"
                   [(ngModel)]="user.physicalCity">            
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            STATE
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <nb-select  style="width:95%"
                        placeholder="Select State"
                        name="state"
                        id="state"
                        [(ngModel)]="user.physicalState">
                <nb-option *ngFor="let item of states" [value]="item">{{ item }}</nb-option>
            </nb-select>
            <button type="button" nbSuffix nbButton ghost (click)="onClearState()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
        </div>
        <div class="col-md-8"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            ZIP
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="zip" 
                   name="zip" 
                   placeholder="Zip"
                   [ngClass]="{'inputError':!validZip() && zipentry.touched}"
                   #zipentry="ngModel"
                   maxlength="5"
                   [(ngModel)]="user.physicalZip">
            <div class="errorText" *ngIf="!validZip() && zipentry.touched">Please enter a 5-digit zip code.</div>
        </div>
        <div class="col"></div>
    </div>
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            PHONE NUMBER *
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input mask="(000) 000-0000"
                   [dropSpecialCharacters]="false"
                   nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="phone" 
                   name="phone" 
                   placeholder="Phone Number"
                   #phoneentry="ngModel"
                   [ngClass]="{'inputError':!validPhone() && phoneentry.touched}"
                   maxlength="14"
                   [(ngModel)]="user.phone">  
            <div class="errorText" *ngIf="!validPhone() && phoneentry.touched">Please provide a valid phone number.</div>
        </div>
        <div class="col"></div>
    </div> 

    <ng-container *ngIf="!isEdit()">
        <div class="row mt-4">
            <div class="col-md-12"></div>
        </div> 
    </ng-container>

    <ng-container *ngIf="isEdit()">

        <div class="row mt-4">
            <div class="col-md-12">
                <hr>
            </div>
        </div> 
        
        <div class="row mb-2">
            <div class="col-md-6 title-text center" style="font-size:15px">
                Associated Seller Business Accounts
            </div>
        </div> 
        
        <div class="row ml-2 table-header-border">
    	    <div class="col-md-11">
    	      <div><span class="table-header-text">SELLER BUSINESS NAME</span></div>
    	    </div>
    	    <div class="col-md-1">
    	      <div><span class="table-header-text"></span></div>
    	    </div>
    	</div>
    	
        <ng-container *ngFor="let item of associatedBusinesses;let i=index">
            <div class="row table-header-border ml-2">
            	<div class="col-md-11 mt-2 mb-2">
        	      <div><span class="table-column-text"> {{ item.businessName }} </span></div>
            	</div>
            	<div class="col-md-1 mt-2 mb-2">
        	      <div>
                    <nb-icon icon="edit-outline" class="details-icon"></nb-icon>
                    <a [routerLink]="" class="link-text ml-1" (click)="onEditSeller(item.accountId)">Details</a>
        	      </div>
            	</div>
        	</div>
        </ng-container>	
    </ng-container>
    
</div> <!-- container-fluid -->
