<div class="container-fluid">

    <div class="row no-gutters">
	    <div class="col-md-3">
          <span class="auction-label-text">CURRENT BID</span>
	    </div>

	    <div class="col-md-3">
          <span class="auction-label-text">BIDDER NAME</span>
	    </div>

	    <div class="col-md-2">
          <span class="auction-label-text">ADMIN</span>
	    </div>

	    <div class="col-md-2">
          <span class="auction-label-text">BIDDER NUMBER</span>
	    </div>
	    
	    <div class="col-md-2">
          <span class="auction-label-text flex-right">TIME</span>
	    </div>
    	
    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-md-12">
		  <div *ngFor="let item of rows">
		     <app-auction-bidder-detail [bid]="item" [timezone]="timezone" type="auction"></app-auction-bidder-detail>
		     <div class="d-md-none mb-3"></div>
		   </div>
		</div>
	</div>

</div> <!-- container-fluid -->
