// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  awsconfig: {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:0c8ff101-2f4a-4ba2-9a7c-cc75b33e0ff9",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_ImBdKvIfr",
    aws_user_pools_web_client_id: "68kbj16blsl2ar05ia83lgllnb",
    oauth: {
        domain: "g2-land-dev.auth.us-east-1.amazoncognito.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://localhost:4200/",
        redirectSignOut: "https://localhost:4200/",
        responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS",

    aws_appsync_authenticationType: "AWS_IAM",
    aws_appsync_graphqlEndpoint: "https://tajf655ivre7fh3bb66xlxd64i.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_appid: "d752anexjbfo7e5t7tkhp3gjdu",
    image_path_url: "assets-land-dev.g2landauctions.com",
    buyer_portal_path_url: "app-dev.g2landauctions.com"
  },
  salemanagementcontactdefault: {
    name: "Jeremy Haag",
    email: "jeremy@g2landauctions.com",
    phone: "(406) 833-0117"
  }
};
