import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';
import { AccountByEmailQuery, 
         UserContactDetailsInput, 
         UpdateUserContactDetailsMutation,
         MeQuery,
         UsersQuery,
         UserQuery,
         BusinessQuery,
         BusinessesQuery,
         UpdateBusinessContactDetailsMutation,
         CreateBusinessMutation,
         DeleteBusinessMutation,
         PhoneNumberDetailInput, 
         AddBusinessUserMutation, 
         DeleteBusinessUserMutation,
         DeleteUserMutation,
         AccountByFirstLastQuery,
         AddNewUserMutation,
         UpdateUserEmailMutation } from '../API.service';

import { accountByEmail, 
         me, 
         users, 
         user, 
         business, 
         businesses,
         accountByFirstLast,
       } from '../../graphql/queries';
       
import { updateUserContactDetails, 
         updateBusinessContactDetails,
         createBusiness,
         deleteBusiness,
         addBusinessUser,
         deleteBusinessUser,
         deleteUser,
         addNewUser } from '../../graphql/mutations';
         
import  { updateUserEmailCustom,
          updateUserRolesCustom,
          accountByEmailVersionCustom } from '../../graphql/custom'

import * as moment from 'moment';

export interface AuctionBidderInfo {
  auctionId: string;
  lotId: string;
  bidderId: string;
}

@Injectable()
export class AccountService {
  usersList:PortalUser[] = [];
  activeUser:PortalUser;
  sellersList:PortalUser[] = [];
  activeSeller:PortalUser;
  
  async loadUsers(forcereload=false, nextToken="",sortDirection="ASC") {
    if(this.usersList.length == 0 || forcereload == true) {
      this.usersList = []; 
      let queryVars = {};
      queryVars['limit'] = 20;
      nextToken.length > 0 ? queryVars['nextToken'] = nextToken : "";
      queryVars['sortDirection'] = sortDirection;
      
      let continueLoop = true;
      while(continueLoop){
        try{
          const response = (await API.graphql(graphqlOperation(users,queryVars))) as {
            data: UsersQuery;
          };
          // console.log('users',response);
          for(let item of response.data.users.items){
            let account = new PortalUser();
            account.loadResults(item);
            this.usersList.push(account);
          }
          continueLoop = response.data.users.nextToken != undefined;
          queryVars['nextToken'] = response.data.users.nextToken;
        }
        catch(err){
          console.log('error loading users list',err);
          throw(err)
        }
      }
    }
    return this.usersList;
  }
  
  async loadSellers(forcereload=false, nextToken="",sortDirection="ASC") {
    if(this.sellersList.length == 0 || forcereload == true) {
      this.sellersList = []; 
      let queryVars = {};
      queryVars['limit'] = 100;
      nextToken.length > 0 ? queryVars['nextToken'] = nextToken : "";
      queryVars['sortDirection'] = sortDirection;
      
      try{
        const response = (await API.graphql(graphqlOperation(businesses,queryVars))) as {
          data: BusinessesQuery;
        };
        for(let item of response.data.businesses.items){
          let account = new PortalUser();
          account.loadResults(item);
          this.sellersList.push(account);
        }
      }
      catch(err){
        console.log('error loading sellers list',err);
        throw(err)
      }
    }
    return this.sellersList;
  }
  
  async createNewUser(email:string,first:string,last:string,phone:string,roles:string[]){
      let queryVars = {};
      queryVars['email'] = email;
      queryVars['givenName'] = first;
      queryVars['familyName'] = last;
      queryVars['phone'] = phone;
      queryVars['userRoles'] = roles; 
      
      try{
        const response = (await API.graphql(graphqlOperation(addNewUser,queryVars))) as {
          data: AddNewUserMutation;
        };
        // console.log('createNewUser',response);
        let account = new PortalUser();
        account.loadResults(response.data.addNewUser);
        return account;
      }
      catch(err){
        console.log('error creating new user',err);
        throw(err)
      }
  }
  
}

@Injectable()
export class PortalUser {

    static _ADMIN  = "ADMIN";
    static _BUYER  = "BUYER";
    static _SELLER = "SELLER";
    
    public _NOTIFICATION_PLAYSOUND                 = "PLAYSOUNDFORBIDS";
    public _NOTIFICATION_AUCTIONSTARTS             = "AUCTIONSTARTS";
    public _NOTIFICATION_TWOHOURSBEFOREAUCTIONENDS = "TWOHOURSBEFOREAUCTIONENDS";
    public _NOTIFICATION_OUTBID                    = "OUTBID";
    public _NOTIFICATION_AUCTIONENDS               = "AUCTIONENDS";
    public _NOTIFICATION_AUCTIONRESULTS            = "AUCTIONRESULTS";
    public _NOTIFICATION_BYEMAIL                   = "BYEMAIL";
  
    public accountId:string;
    public email:string;
    public familyName:string;
    public givenName:string;
    public nameTitle:string;
    public businessName:string;
    public lastLoginDt:string;
    public loginCtr:number;
    public phone:string;
    public physicalAddress: string[];
    public physicalCity:string;
    public physicalState:string;
    public physicalZip:string;
    public physicalCountry:string;
    public physicalCountryStateCity:string;
    public billingAddress: string[];
    public billingCity:string;
    public billingState:string;
    public billingZip:string;
    public billingCountry:string;
    public termsOfService:boolean;
    public receiveInfo:boolean;
    public userRoles: string[];
    public businesses: string[];
    public notifications: string[];
    public version:number;
    public activeBidderIds: AuctionBidderInfo[];
    public associatedBusinesses:PortalUser[];

    public associatedBusinessIds:string[];
    public modifiedAssociatedBusinessIds:string[];

    constructor() {
      this.accountId = "";
    }

    isAdmin(){
      return this.userRoles.includes(PortalUser._ADMIN);
    }
    
    setAdmin(setrole:boolean){
      if(!this.isAdmin() && setrole){
        this.userRoles.push(PortalUser._ADMIN);
      }
      else if(this.isAdmin() && !setrole){
        let index = this.userRoles.findIndex((el)=>{return el == PortalUser._ADMIN});
        this.userRoles.splice(index,1);
      }
    }

    isBuyer(){
      return this.userRoles.includes(PortalUser._BUYER);
    }

    setBuyer(setrole:boolean){
      if(!this.isBuyer() && setrole){
        this.userRoles.push(PortalUser._BUYER);
      }
      else if(this.isBuyer() && !setrole){
        let index = this.userRoles.findIndex((el)=>{return el == PortalUser._BUYER});
        this.userRoles.splice(index,1);
      }
    }

    isSeller(){
      return this.userRoles.includes(PortalUser._SELLER);
    }
    
    setSeller(setrole:boolean){
      if(!this.isSeller() && setrole){
        this.userRoles.push(PortalUser._SELLER);
      }
      else if(this.isSeller() && !setrole){
        let index = this.userRoles.findIndex((el)=>{return el == PortalUser._SELLER});
        this.userRoles.splice(index,1);
      }
    }
    
    setNotifications(notificationMap){
      this.notifications = [];
      if(this._NOTIFICATION_AUCTIONENDS in notificationMap && notificationMap[this._NOTIFICATION_AUCTIONENDS]){
        this.notifications.push(this._NOTIFICATION_AUCTIONENDS);
      }

      if(this._NOTIFICATION_PLAYSOUND in notificationMap && notificationMap[this._NOTIFICATION_PLAYSOUND]){
        this.notifications.push(this._NOTIFICATION_PLAYSOUND);
      }

      if(this._NOTIFICATION_AUCTIONSTARTS in notificationMap && notificationMap[this._NOTIFICATION_AUCTIONSTARTS]){
        this.notifications.push(this._NOTIFICATION_AUCTIONSTARTS);
      }

      if(this._NOTIFICATION_TWOHOURSBEFOREAUCTIONENDS in notificationMap && notificationMap[this._NOTIFICATION_TWOHOURSBEFOREAUCTIONENDS]){
        this.notifications.push(this._NOTIFICATION_TWOHOURSBEFOREAUCTIONENDS);
      }

      if(this._NOTIFICATION_OUTBID in notificationMap && notificationMap[this._NOTIFICATION_OUTBID]){
        this.notifications.push(this._NOTIFICATION_OUTBID);
      }

      if(this._NOTIFICATION_AUCTIONRESULTS in notificationMap && notificationMap[this._NOTIFICATION_AUCTIONRESULTS]){
        this.notifications.push(this._NOTIFICATION_AUCTIONRESULTS);
      }

      if(this._NOTIFICATION_BYEMAIL in notificationMap && notificationMap[this._NOTIFICATION_BYEMAIL]){
        this.notifications.push(this._NOTIFICATION_BYEMAIL);
      }
    }
    
    getNotifications(){
      var returnValue = {};
      returnValue[this._NOTIFICATION_AUCTIONENDS] = this.notifyWhenAuctionEnds();
      returnValue[this._NOTIFICATION_PLAYSOUND] = this.playSoundForBids();
      returnValue[this._NOTIFICATION_AUCTIONSTARTS] = this.notifyWhenAuctionStarts();
      returnValue[this._NOTIFICATION_TWOHOURSBEFOREAUCTIONENDS] = this.notifyTwoHoursBeforeAuctionEnds();
      returnValue[this._NOTIFICATION_OUTBID] = this.notifyWhenOutBid();
      returnValue[this._NOTIFICATION_AUCTIONRESULTS] = this.notifyAuctionResults();
      returnValue[this._NOTIFICATION_BYEMAIL] = this.notifyByEmail();
      return returnValue;
    }
    
    playSoundForBids(){
      return this.notifications.includes(this._NOTIFICATION_PLAYSOUND);
    }

    notifyWhenAuctionStarts(){
      return this.notifications.includes(this._NOTIFICATION_AUCTIONSTARTS);
    }

    notifyTwoHoursBeforeAuctionEnds(){
      return this.notifications.includes(this._NOTIFICATION_TWOHOURSBEFOREAUCTIONENDS);
    }

    notifyWhenOutBid(){
      return this.notifications.includes(this._NOTIFICATION_OUTBID);
    }

    notifyWhenAuctionEnds(){
      return this.notifications.includes(this._NOTIFICATION_AUCTIONENDS);
    }

    notifyAuctionResults(){
      return this.notifications.includes(this._NOTIFICATION_AUCTIONRESULTS);
    }

    notifyByEmail(){
      return this.notifications.includes(this._NOTIFICATION_BYEMAIL);
    }
    
    async load(email:string) {
        let queryVars = {};
        queryVars['email'] = email;

        try{
          const response = (await API.graphql(graphqlOperation(accountByEmail,queryVars))) as {
            data: AccountByEmailQuery;
          };

          if(response.data.accountByEmail == undefined){
            throw new Error('Error retrieving profile. Contact your administrator.');
          }
          this.loadResults(response.data.accountByEmail);
        }
        catch(err){
          console.log('error loading account profile',err);
          throw(err)
        }
      
      return this;
    }

    async loadVersion() {
        let queryVars = {};
        queryVars['email'] = this.email;

        try{
          const response = (await API.graphql(graphqlOperation(accountByEmailVersionCustom,queryVars))) as {
            data: AccountByEmailQuery;
          };

          if(response.data.accountByEmail == undefined){
            throw new Error('Error retrieving profile. Contact your administrator.');
          }
          this.version = response.data.accountByEmail['version'];
          this.accountId = response.data.accountByEmail['accountId'];
        }
        catch(err){
          console.log('error loading account profile',err);
          throw(err)
        }
      
      return this;
    }

    async get(accountId:string) {
        let queryVars = {};
        queryVars['accountId'] = accountId;

        try{
          const response = (await API.graphql(graphqlOperation(user,queryVars))) as {
            data: UserQuery;
          };
          // console.log('getUserAccount',response);
          if(response.data.user == undefined){
            throw new Error('Error retrieving user account. Contact your administrator.');
          }
          this.loadResults(response.data.user);
        }
        catch(err){
          console.log('error loading user account',err);
          throw(err)
        }
      return this;
    }

    async updateEmail(newEmail) {
        let queryVars = {};
        queryVars['accountId'] = this.accountId;
        queryVars['newemail'] = newEmail;

        try{
          const response = (await API.graphql(graphqlOperation(updateUserEmailCustom,queryVars))) as {
            data: UpdateUserEmailMutation;
          };
          // console.log('update email',response);
          if(response.data.updateUserEmail == undefined){
            throw new Error('Error updating email in user account. Contact your administrator.');
          }
          this.version = response.data.updateUserEmail.version;
          this.email = newEmail;
        }
        catch(err){
          console.log('error updating email in user account',err);
          throw(err)
        }
      return this;
    }

    async searchByLastFirst(lastName:string,firstName:string="") {
        var returnValue = [];
        let queryVars = {};
        queryVars['givenName'] = firstName;
        queryVars['familyName'] = lastName;

        try{
          const response = (await API.graphql(graphqlOperation(accountByFirstLast,queryVars))) as {
            data: AccountByFirstLastQuery;
          };

          if(response.data.accountByFirstLast != undefined){
            for(let item of response.data.accountByFirstLast.items){
              let account = new PortalUser();
              account.loadResults(item);
              returnValue.push(account);
            }
          }
        }
        catch(err){
          console.log('Error retrieving user by last name',err);
          throw(err)
        }
      
      return returnValue;
    }
    
    async me() {
        try{
          const response = (await API.graphql(graphqlOperation(me))) as {
            data: MeQuery;
          };

          if(response.data.me == undefined){
            throw new Error('Error retrieving profile. Contact your administrator.');
          }
          this.loadResults(response.data.me);
        }
        catch(err){
          console.log('error loading account profile',err);
          throw(err)
        }
      
      return this;
    }

    async update(){
      let updateVars = this.getUpdateVars();
      try{
        const response = (await API.graphql(graphqlOperation(updateUserContactDetails,{accountId:this.accountId,expectedVersion:this.version,input:updateVars}))) as {
          data: UpdateUserContactDetailsMutation;
        };

        if(response.data == undefined || response.data.updateUserContactDetails == undefined){
          throw new Error('Error updating profile. Contact your administrator.');
        }
        this.loadResults(response.data.updateUserContactDetails);
      }
      catch(err){
        var errorMsg = err.errors[0].message;
        console.log('error loading user',err);
        throw(errorMsg)
      }
      return this;
    }

    async updateRoles(){
      var updateVars = {};
      updateVars['userRoles'] = this.userRoles;
      try{
        const response = (await API.graphql(graphqlOperation(updateUserRolesCustom,{accountId:this.accountId,expectedVersion:this.version,input:updateVars}))) as {
          data: UpdateUserContactDetailsMutation;
        };

        if(response.data == undefined || response.data.updateUserContactDetails == undefined){
          throw new Error('Error updating user roles. Contact your administrator.');
        }
        // console.log('update user roles',response.data.updateUserContactDetails);
        this.userRoles = response.data.updateUserContactDetails.userRoles;
        this.version = response.data.updateUserContactDetails.version;
      }
      catch(err){
        var errorMsg = err.errors[0].message;
        console.log('error updating user roles',err);
        throw(errorMsg)
      }
      return this;
    }
    
    async delete(){    
      try{
        await this.deleteBusinessAssociations();
        const response = (await API.graphql(graphqlOperation(deleteUser,{accountId:this.accountId}))) as {
          data: DeleteBusinessMutation;
        };
  
        if(response.data == undefined || response.data.deleteBusiness == undefined){
          throw new Error('Error deleting business. Contact your administrator.');
        }
        return;
      }
      catch(err){
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error deleting business',err);
        throw(errorMsg)
      }
    }
    
    getUpdateVars(){
      var updateVars = {};
      this.email != undefined ? (updateVars['email'] = this.email) : "";
      this.familyName != undefined ? (updateVars['familyName'] = this.familyName) : "";
      this.givenName != undefined ? (updateVars['givenName'] = this.givenName) : "";
      this.businessName != undefined ? (updateVars['businessName'] = this.businessName) : "";
      this.phone != undefined ? (updateVars['phone'] = this.phone) : "";
      this.physicalAddress != undefined ? (updateVars['physicalAddress'] = this.physicalAddress) : [];
      this.physicalCity != undefined ? (updateVars['physicalCity'] = this.physicalCity) : "";
      this.physicalState != undefined ? (updateVars['physicalState'] = this.physicalState) : "";
      this.physicalZip != undefined ? (updateVars['physicalZip'] = this.physicalZip) : "";
      this.physicalCountry != undefined ? (updateVars['physicalCountry'] = this.physicalCountry) : "";
      this.billingAddress != undefined ? (updateVars['billingAddress'] = this.billingAddress) : [];
      this.billingCity != undefined ? (updateVars['billingCity'] = this.billingCity) : "";
      this.billingState != undefined ? (updateVars['billingState'] = this.billingState) : "";
      this.billingZip != undefined ? (updateVars['billingZip'] = this.billingZip) : "";
      this.billingCountry != undefined ? (updateVars['billingCountry'] = this.billingCountry) : "";
      this.termsOfService != undefined ? (updateVars['termsOfService'] = this.termsOfService) : "";
      this.receiveInfo != undefined ? (updateVars['receiveInfo'] = this.receiveInfo) : "";
      this.userRoles != undefined ? (updateVars['userRoles'] = this.userRoles) : "";
      this.businesses != undefined ? (updateVars['businesses'] = this.businesses) : "";
      this.notifications != undefined ? (updateVars['notifications'] = this.notifications) : "";
      this.nameTitle != undefined ? (updateVars['nameTitle'] = this.nameTitle) : "";
      return updateVars;
    }
    
    loadResults(returnResults){
      this.accountId = returnResults.accountId != undefined ? returnResults.accountId : "";
      this.email = returnResults.email != undefined ? returnResults.email : undefined;
      this.familyName = returnResults.familyName != undefined ? returnResults.familyName : undefined;
      this.givenName = returnResults.givenName != undefined ? returnResults.givenName : undefined;
      this.businessName = returnResults.businessName != undefined ? returnResults.businessName : undefined;
      
      this.lastLoginDt = returnResults.lastLoginDt != undefined ? returnResults.lastLoginDt : undefined;
      this.loginCtr = returnResults.loginCtr != undefined ? returnResults.loginCtr : undefined;
      
      this.phone = returnResults.phone != undefined ? returnResults.phone : undefined;
      this.physicalAddress = returnResults.physicalAddress != undefined ? returnResults.physicalAddress : [];
      this.physicalCity = returnResults.physicalCity != undefined ? returnResults.physicalCity : undefined;
      this.physicalState = returnResults.physicalState != undefined ? returnResults.physicalState : undefined;
      this.physicalZip = returnResults.physicalZip != undefined ? returnResults.physicalZip : undefined;
      this.physicalCountry = returnResults.physicalCountry != undefined ? returnResults.physicalCountry : undefined;
      this.physicalCountryStateCity = returnResults.physicalCountryStateCity != undefined ? returnResults.physicalCountryStateCity : undefined;
      this.billingAddress = returnResults.billingAddress != undefined ? returnResults.billingAddress : [];
      this.billingCity = returnResults.billingCity != undefined ? returnResults.billingCity : undefined;
      this.billingState = returnResults.billingState != undefined ? returnResults.billingState : undefined;
      this.billingZip = returnResults.billingZip != undefined ? returnResults.billingZip : undefined;
      this.billingCountry = returnResults.billingCountry != undefined ? returnResults.billingCountry : undefined;
      this.termsOfService = returnResults.termsOfService != undefined ? returnResults.termsOfService : undefined;
      this.receiveInfo = returnResults.receiveInfo != undefined ? returnResults.receiveInfo : undefined;
      this.userRoles = returnResults.userRoles != undefined ? returnResults.userRoles : undefined;
      this.businesses = returnResults.businesses != undefined ? returnResults.businesses : undefined;
      this.notifications = returnResults.notifications != undefined ? returnResults.notifications : [];
      this.nameTitle = returnResults.nameTitle != undefined ? returnResults.nameTitle : undefined;
      this.version = returnResults.version != undefined ? returnResults.version : 0;
      this.activeBidderIds = returnResults.activeBidderIds != undefined ? returnResults.activeBidderIds : undefined;
      
      this.associatedBusinesses = returnResults.associatedBusinesses != undefined ? returnResults.associatedBusinesses : [];
      this.associatedBusinessIds = [];
      this.modifiedAssociatedBusinessIds = [];
      for(let item of this.associatedBusinesses){
        this.associatedBusinessIds.push(item['accountId']);
        this.modifiedAssociatedBusinessIds.push(item['accountId']);
      }
    }

    getFullName():string {
      var returnValue = "";
      if(this.givenName.length===0 && this.familyName.length===0){
        returnValue = this.email;
      }
      else{
        var separator = (this.givenName.length > 0) ? ' ' : ''
        returnValue =  this.givenName + separator + this.familyName;
      }
      return returnValue;
    }
    
    getDisplayablePhysicalLocation():string{
      let city = (this.physicalCity != undefined && this.physicalCity.length > 0) ? this.physicalCity + ", " : "";
      let state = (this.physicalState != undefined && this.physicalState.length > 0) ? this.physicalState + " ": "";
      let zip = (this.physicalZip != undefined && this.physicalZip.length > 0) ? this.physicalZip : "";
      return city + state + zip;
    }
    
    getBidder(auctionId,lotId){
        let returnValue = undefined;
        if(this.activeBidderIds != undefined && this.activeBidderIds.length > 0 ){
          let activeBidder = this.activeBidderIds.find((el)=>{return el.auctionId==auctionId && el.lotId==lotId});
          returnValue = activeBidder!=undefined ? activeBidder.bidderId : undefined;
        }
        return returnValue;
    }
    
  async deleteBusinessAssociations(){
    /* Delete business associations */
    for(let item of this.associatedBusinessIds){
        await this.deleteProxyUser(this.accountId,item);
    }
  }
  

  async deleteProxyUser(userAccountId:string,businessAccountId:string){  
    // console.log(userAccountId," ",businessAccountId);
    try{
      const response = (await API.graphql(graphqlOperation(deleteBusinessUser,{accountId:userAccountId,businessAccountId:businessAccountId}))) as {
        data: DeleteBusinessUserMutation;
      };
      
      console.log(response);
      if(response.data == undefined || response.data.deleteBusinessUser == undefined){
        throw new Error('Error deleting proxy user from business. Contact your administrator.');
      }
      return;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting proxy user from business',err);
      throw(errorMsg)
    }
  }
    
}
