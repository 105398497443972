import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, PortalUser } from '../../model/portaluser.model';


@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.scss']
})
export class ManageusersComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  searchuser:string;
  usersList:PortalUser[] = [];
  
  @ViewChild('myuserslist') myUsersList;  

  menuCards = [];

  constructor(private accountService: AccountService,
              private router: Router,) { }

  ngOnInit(): void {
    this.loadMenuCards();
    this.loadUsers(true);
  }
  
  /**
   * server based api calls
   **/
  async loadUsers(forcereload=false){
    this.processing = true;
    this.errorMsg = "";
    try {
      this.usersList = await this.accountService.loadUsers(true);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while retrieving users list. " + err;
      console.log('error while retrieving users list. ',err);
    }
  }

  async updateUserRoles(user:PortalUser){
    this.processing = true;
    this.errorMsg = "";
    try {
      await user.updateRoles()
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while updating user roles. " + err;
      console.log('error while updating users roles.');
    }
  }
  
  async onUpdateFilter($event){
    await this.myUsersList.filter(this.searchuser);
  }
  
  loadMenuCards(){
    this.menuCards = [];
    var menuCard = {
      "title": "+ REPORTS",
      "route": "/reports"
    }
    this.menuCards.push(menuCard);
    var menuCard = {
      "title": "+ ISSUE RESOLUTION",
      "route": "/issueresolution"
    }
    this.menuCards.push(menuCard);
    var menuCard = {
      "title": "+ INVOICING",
      "route": "/invoicing"
    }
    this.menuCards.push(menuCard);
  }
  
  getRecordsLength(){
   return this.usersList.length;
  }
  
  async receiveMessage($event) {
    if($event['message']=='edituser'){
    	this.router.navigate(['/userdetails', "EDIT",$event['parms']['accountId']]);
    }
    else if($event['message']=='updateroles'){
      this.updateUserRoles($event['parms']['user']);
    }
  }
  
  onNewUser(){
    this.router.navigate(['/userdetails', "NEW","NEW"]);
  }
  

}
