<div class="container-fluid">

    <div class="row no-gutters table-header-border">
    	
	    <div class="col-md-2 d-md-block d-none">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(1)"> FIRST NAME 
              <span *ngIf="isSortAsc(1)">&nbsp;&#9650;</span>
              <span *ngIf="isSortDesc(1)">&nbsp;&#9660;</span>
          </a>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
          <a [routerLink]="" class="table-header-text-link" (click)="onSort(2)"> LAST NAME 
              <span *ngIf="isSortAsc(2)">&nbsp;&#9650;</span>
              <span *ngIf="isSortDesc(2)">&nbsp;&#9660;</span>
          </a>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text"> ADMIN </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text"> BIDDER </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text"> SELLER </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text">  </span></div>
	    </div>
    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-md-12">
		  <div *ngFor="let item of filteredList | paginate: { id: 'playermatch_pagination',
	                                                  		  itemsPerPage: 10,
	                                                  		  currentPage: p,
	                                                  		  totalItems: filteredList.length }">
		     <app-userdetail [user]="item" (messageEvent)="receiveMessage($event)"></app-userdetail>
		     <div class="d-md-none mb-3"></div>
		   </div>
		</div>
	</div>
	
    <div class="row mt-5 mb-5 no-gutters">
        <div class="col-md-12">
          <pagination-controls id="playermatch_pagination" maxSize="10" class="list-custom-pagination" (pageChange)="p = $event" autoHide="true" previousLabel="" nextLabel=""></pagination-controls>          
        </div>
        <div class="col"></div>
    </div>          


</div> <!-- container-fluid -->
