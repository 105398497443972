import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuctionModelService, AuctionModel } from '../../model/auction.model';
import { AuctionStatus} from '../../API.service';
import { NbDialogService } from '@nebular/theme';
import { ConfirmdialogComponent } from '../../confirmdialog/confirmdialog.component';
import { SellerService, Seller } from '../../model/seller.model';
import { AuctionReportPartialPaymentDialogComponent } from '../auction-report-partial-payment-dialog/auction-report-partial-payment-dialog.component';
import { GlobalStateService, EditModelType } from '../../services/globalstate.service';


@Component({
  selector: 'app-auctionedithome',
  templateUrl: './auctionedithome.component.html',
  styleUrls: ['./auctionedithome.component.scss']
})
export class AuctionedithomeComponent implements OnInit {
  errorMsg = "";
  successMsg = "";
  processing: boolean = false;

  savedisabled = {
    "auctionsaleinfo":false,
    "auctionconfigure":false
  };

  sub;
  editmode:string = "EDIT";
  auctionId:string = undefined;
  filterStatus:number = 0;
  auctionTitle:string = "";
  initialSellerId:string = "";
  sellersList:Seller[] = [];
  saveLastTabTitle;
  auctionEditTab:number = 1;
  lotTab:number = 1;
  lotId:string = "0";

  pagelist = {
    "SALE INFORMATION":1,
    "CONFIGURE":2,
    "LOTS":3,
    "BIDS":4,
    "AUCTION REPORT":5
  }
  currentPage:string = "SALE INFORMATION";

  @ViewChild('auctionsaleinfo') auctionsaleinfo;
  @ViewChild('auctionconfigure') auctionconfigure;
  @ViewChild('lots') lots;
  @ViewChild('bidhome') bidhome;
  @ViewChild('auctionreport') auctionreport;
  @ViewChild('auctionstatusflow') auctionstatusflow;
  @Output() messageEvent = new EventEmitter<any>();

  constructor(private activatedRoute:ActivatedRoute,
              private router: Router,
              private sellerService: SellerService,
              private dialogService: NbDialogService,
              private auctionModel: AuctionModelService,
              private location: Location,
              private globalstate: GlobalStateService) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.auctionTitle = "";
    this.sub=this.activatedRoute.paramMap.subscribe(params => {
          this.editmode = params.get('editmode');
          this.auctionId = params.get('id');
          this.filterStatus = params.get('filterStatus') != undefined ? parseInt(params.get('filterStatus')) : 0;
          this.initialSellerId = params.get('sellerId') != undefined ? params.get('sellerId') : "";
          this.auctionEditTab = params.get('auctiontab') != undefined ? parseInt(params.get('auctiontab')) : 1;
          this.lotTab = params.get('lottab') != undefined ? parseInt(params.get('lottab')) : 0;
          this.lotId = params.get('lotId') != undefined ? params.get('lotId') : "0";

          if(this.editmode == "EDIT") {
            this.auctionModel.activeAuction = new AuctionModel();
            this.loadAuction();
            this.globalstate.editNewInProgress = false;
            this.globalstate.editNewIsValid = true;
          }
          else{
            this.initNewAuction();
            this.globalstate.editNewInProgress = true;
            this.globalstate.editNewIsValid = false;
            this.globalstate.saveCallbackThis = this;
            this.globalstate.saveCallback = this.saveAuctionFromMessage;
            this.globalstate.editType = EditModelType.AUCTION;
            this.setTab(this.auctionEditTab);
          }
      });
  }

  ngOnDestroy() {
     this.sub.unsubscribe();
  }

  /**
   * server based api calls
   **/
  async loadAuction(tabNumber:number=0) {
    this.processing = true;
    try {
      await this.auctionModel.activeAuction.get(this.auctionId);
      await this.loadSellers();
      this.auctionsaleinfo.sellersList = this.sellersList;
      this.auctionsaleinfo.loadPageData();
      this.bidhome.loadPageData();
      this.auctionreport.loadPageData();
      this.auctionTitle = this.auctionModel.activeAuction.title;
      this.auctionstatusflow.loadPageData();
      
      if(tabNumber == 0){
        this.setTab(this.auctionEditTab); 
      }
      
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      console.log('error while retrieving auction',err);
    }
  }

  /**
   * server based api calls
   **/
  async initNewAuction() {
    this.auctionModel.activeAuction = new AuctionModel();
    this.auctionModel.activeAuction.initNewAuction();
    this.auctionTitle = "New Auction";
    await this.loadSellers();
    this.auctionsaleinfo.sellersList = this.sellersList;
    this.initialSellerId != undefined && this.initialSellerId != "0" ? this.auctionsaleinfo.initSeller(this.initialSellerId) : "";
  }

  async loadSellers() {
    this.processing = true;
    try {
      this.sellersList = await this.sellerService.loadSellers();
      this.sellersList.sort(this.compareAscBusiness);
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      console.log('error while retrieving sellers list');
    }
  }

  async saveAuction() {
    var returnValue = true;
    var requiredFieldsList = this.requiredFields();
    if(requiredFieldsList.length > 0){
      this.errorMsg = "Please enter the required fields: " + requiredFieldsList;
      return;
    }

    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";

    try {
      if(this.auctionModel.activeAuction.auctionId == undefined ||
        this.auctionModel.activeAuction.auctionId.length == 0) {
        await this.auctionModel.activeAuction.create();
        this.updateURL();
        this.editmode  = "EDIT";
        this.auctionId = this.auctionModel.activeAuction.auctionId;
        this.globalstate.editNewInProgress = false;
        this.globalstate.editNewIsValid = true;
      }
      await this.auctionsaleinfo.saveFiles();
      await this.auctionModel.activeAuction.update();
      this.auctionsaleinfo.loadPageData();
      this.bidhome.loadPageData();
      this.auctionTitle = this.auctionModel.activeAuction.title;
      this.successMsg = "Auction successfully saved.";
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      this.errorMsg = "Error saving auction: " + err;
      returnValue = false;
    }
    return returnValue;
  }

  async updateAuctionExtensionInterval() {
    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";
    try {
      await this.auctionModel.activeAuction.updateClosingExtensionInterval();
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      this.errorMsg = "Error update auction interval: " + err;
    }
  }

  async updateAuctionStatus(status:AuctionStatus) {
    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";
    try {
      await this.auctionModel.activeAuction.updateStatus(status);
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      this.errorMsg = "Error update auction status: " + err;
    }
  }

  async updateAllAuctionReportItemsToDirectPaid() {
    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";
    try {
      await this.auctionModel.activeAuctionReport.moveLineItemsToDirectPaidStatus();
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      this.errorMsg = "Error updating auction report items to direct paid status: " + err;
    }
  }

  async deleteAuction() {
    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";
    try {
      await this.auctionModel.activeAuction.deleteAuctionAndAssets();
      this.successMsg = "Auction deleted.";
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      this.errorMsg = "Error deleting auction: " + err;
    }
  }

  /**
   * html page handlers
   **/
  onSelectTab(tabTitle) {
    var urlParms = this.location.path().split('/');
    urlParms[6] = this.pagelist[tabTitle];
    this.lotTab = parseInt(urlParms[7]);
    this.lotId = urlParms[8];
    
    var newUrl = urlParms.join('/');
    this.location.go(newUrl);
    
    this.saveLastTabTitle = tabTitle;
    if(this.globalstate.editNewInProgress && this.globalstate.editType == EditModelType.LOT && this.saveLastTabTitle != "LOTS"){
        this.globalstate.routeCallbackThis = this;
        this.globalstate.routeCallback = this.selectTabWhenOnLots;
        this.auctionConfirmation("unsavedchanges");
    }
    else{
      this.selectTab();
    }
  }
  
  selectTab(localThis=this){
    localThis.currentPage = localThis.saveLastTabTitle;
    if(localThis.getPage()===3){
      localThis.lots.initialize(this.lotTab,this.lotId);
    }
  }

  selectTabWhenOnLots(localThis=this){
    localThis.currentPage = localThis.saveLastTabTitle;
    localThis.globalstate.init();
    if(localThis.getPage()===3){
      localThis.lots.initialize();
    }
  }

  onBackToAuctionList() {
      if(this.globalstate.editNewInProgress){
        this.globalstate.routeCallbackThis = this;
        this.globalstate.routeCallback = this.backToAuctionsList;
        this.auctionConfirmation("unsavedchanges");
      }
      else{
        this.backToAuctionsList();
      }
  }
  
  backToAuctionsList(localThis=this){
  	  localThis.router.navigate(['/manageauctions',localThis.filterStatus]);
  }

  getPage() {
    return this.pagelist[this.currentPage];
  }
  
  /*
  * Other
  */
  async receiveMessage($event) {
    if($event['message'] == 'saveauction') {
      this.saveAuctionFromMessage();
    }
    else if($event['message'] == 'auctionsetup') {
      await this.updateAuctionStatus(AuctionStatus.SETUP);
    }
    else if($event['message'] == 'postauctioncalendar') {
      await this.updateAuctionStatus(AuctionStatus.CALENDAR_POSTED);
    }
    else if($event['message'] == 'postauctionsaleinfo') {
      await this.updateAuctionStatus(AuctionStatus.SALE_INFO_POSTED);
    }
    else if($event['message'] == 'postauctionlots') {
      await this.updateAuctionStatus(AuctionStatus.LOTS_POSTED);
    }
    else if($event['message'] == 'startauction') {
      await this.updateAuctionStatus(AuctionStatus.LIVE);
    }
    else if($event['message'] == 'closeauction') {
      this.auctionConfirmation("closeauction");
    }
    else if($event['message']=='settleauction'){
      this.auctionConfirmation("settleauction");
    }
    else if($event['message'] == 'generateauctionsalereport') {
      console.log($event);
    }
    else if($event['message'] == 'generateauctionsettlementsheet') {
      console.log($event);
    }
    else if($event['message'] == 'deleteauction') {
      this.auctionConfirmation("deleteauction");
    }
    else if($event['message'] == 'newlotsloaded') {
      await this.loadAuction(this.pagelist["LOTS"]);
    }
    else if($event['message'] == 'auctionsaleinforequiredfields') {
      this.savedisabled['auctionconfigure'] = !$event['parms']['requiredfields'] || !this.auctionconfigure.validateFormData();
      this.savedisabled['auctionsaleinfo'] = !$event['parms']['requiredfields'] || !this.auctionconfigure.validateFormData();
      this.globalstate.editNewIsValid = !this.savedisabled['auctionconfigure'] && !this.savedisabled['auctionsaleinfo'];
    }
    else if($event['message'] == 'changeextensioninterval') {
      await this.updateAuctionExtensionInterval();
    }
    else if($event['message'] == 'auction-updated') {
      // Tell the counter to update
      this.auctionstatusflow.loadPageData();
      this.auctionsaleinfo != undefined ? this.auctionsaleinfo.loadTimes() : "";
    }
    else if($event['message']=='directsettlement'){
      this.auctionConfirmation("directsettlement");
    }
    else if($event['message']=='partialpayment'){
      this.openPartialPaymentDialog();
    }
  }
  
  async saveAuctionFromMessage(localThis=this){
      localThis.auctionsaleinfo.save();
      var returnValue = await localThis.saveAuction();
      return returnValue;
  }

  auctionConfirmation(type:string) {
    let localThis = this;
    this.dialogService.open(ConfirmdialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          type:type,
          editNewIsValid: this.globalstate.editNewIsValid,
          saveCallbackThis: this.globalstate.saveCallbackThis,
          routeCallbackThis: this.globalstate.routeCallbackThis,
          saveCallback: this.globalstate.saveCallback,
          routeCallback: this.globalstate.routeCallback
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  openPartialPaymentDialog() {
    let localThis = this;
    this.dialogService.open(AuctionReportPartialPaymentDialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context: {

        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  async processMsg(event) {
    // console.log('processMsg',event);
    if(event != undefined) {
      var msg = event['message'];
      if(msg === "deleteauction") {
        await this.deleteAuction();
        this.onBackToAuctionList();
      }
      else if(msg === "closeauction") {
        await this.updateAuctionStatus(AuctionStatus.CLOSED);
        this.auctionreport.loadPageData();
      }
      else if(msg === "directsettlement") {
        await this.updateAuctionStatus(AuctionStatus.SETTLED);
        await this.updateAllAuctionReportItemsToDirectPaid();
        this.auctionreport.loadPageData();
      }
      else if(msg === "settleauction") {
        await this.updateAuctionStatus(AuctionStatus.SETTLED);
        this.auctionreport.loadPageData();
      }
    }
  }
  
  compareAscBusiness(a:Seller,b:Seller) {
    let valueA = a.businessName.toUpperCase();
    let valueB = b.businessName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  requiredFields(){
    var missingFields = [];
    if(this.auctionModel.activeAuction.title == undefined || this.auctionModel.activeAuction.title.length == 0){
      missingFields.push("SALE NAME");
    }
    if(this.auctionModel.activeAuction.startTime == undefined || this.auctionModel.activeAuction.startTime.length == 0){
      missingFields.push("START DATE AND TIME");
    }
    if(this.auctionModel.activeAuction.endTime == undefined || this.auctionModel.activeAuction.endTime.length == 0){
      missingFields.push("END DATE AND TIME");
    }
    return missingFields.join(', ');
  }

  auctionExists(){
    return (this.auctionModel.activeAuction != undefined && this.auctionModel.activeAuction.auctionId != undefined && this.auctionModel.activeAuction.auctionId.length > 0)
  }
  
  setTab(tabNumber:number){
    var foundKey = Object.keys(this.pagelist).filter((el) => {return this.pagelist[el] == tabNumber});
    if(foundKey.length > 0){
      this.onSelectTab(foundKey);
    }
  }
  
  updateURL(){
    if(this.editmode == "NEW"){
      this.auctionId = this.auctionModel.activeAuction.auctionId;
      var urlParms = this.location.path().split('/');
      urlParms[2] = "EDIT";
      urlParms[3] = this.auctionId;
      var newUrl = urlParms.join('/');
      this.location.go(newUrl);
    }
  }
  
  
}
