import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AccountService, PortalUser } from '../../model/portaluser.model';
import { GlobalStateService, EditModelType } from '../../services/globalstate.service';
import { NbDialogService } from '@nebular/theme';
import { ConfirmdialogComponent } from '../../confirmdialog/confirmdialog.component';


@Component({
  selector: 'app-user-details-home',
  templateUrl: './user-details-home.component.html',
  styleUrls: ['./user-details-home.component.scss']
})
export class UserDetailsHomeComponent implements OnInit {
  errorMsg = "";
  successMsg = "";
  processing: boolean = false;
  
  user:PortalUser = undefined;
  accountId:string = undefined;
  sub;
  editmode:string = "EDIT";
  
  pagelist = {
    "DETAILS":1
  }
  currentPage:string = "DETAILS";

  @ViewChild('useredit') useredit;
  @Output() messageEvent = new EventEmitter<any>();

  constructor(private activatedRoute:ActivatedRoute,
              private accountService: AccountService,
              private router: Router,
              private dialogService: NbDialogService,
              private globalstate: GlobalStateService) { }

  ngOnInit(): void {
    this.sub=this.activatedRoute.paramMap.subscribe(params => { 
          this.accountId = params.get('id'); 
          this.editmode = params.get('editmode'); 
          if(this.editmode == "EDIT"){
            this.loadUser();
            this.globalstate.editNewInProgress = false;
            this.globalstate.editNewIsValid = true;
          }
          else{
            this.user = new PortalUser();
            this.globalstate.editNewInProgress = true;
            this.globalstate.editNewIsValid = false;
            this.globalstate.saveCallbackThis = this;
            this.globalstate.saveCallback = this.saveUserFromMessage;
            this.globalstate.editType = EditModelType.USER;
          }
      });    
  }
  
  /**
   * server based api calls
   **/
  async loadUser() {
    this.processing = true;
    try {
      var localUser = new PortalUser();
      this.user = await localUser.get(this.accountId);
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      console.log('error while retrieving user account',err);
    }
  }

  async saveUser() {
    var returnValue = true;
    this.errorMsg = "";
    this.successMsg = "";
    
    this.processing = true;
    try {
      this.user = await this.user.update();
      this.successMsg = "Successfully updated user account";
      this.useredit.loadPageData();
      this.processing = false;
      this.globalstate.editNewInProgress = false;
      this.globalstate.editNewIsValid = true;
    }
    catch(err) {
      this.processing = false;
      this.errorMsg = "Error updating user account: " + err;
      console.log('error while retrieving user account',err);
      returnValue = false;
    }
    return returnValue;
  }
  
  async saveUserFromMessage(localThis=this){
      var returnValue = await localThis.createNewUser();
      return returnValue;
  }
  
  async createNewUser(){
    var returnValue = true;
    this.processing = true;
    try {
      let roles = [PortalUser._BUYER];
      await this.accountService.createNewUser(this.user.email,this.user.givenName,this.user.familyName,this.user.phone,roles);
      this.user = await this.user.loadVersion();
      this.user = await this.user.update();
      this.editmode == "EDIT";
      this.useredit.loadPageData();
      this.successMsg = "Successfully created new user account";
      this.processing = false;
      this.globalstate.editNewInProgress = false;
      this.globalstate.editNewIsValid = true;
    }
    catch(err){
      this.processing = false;
      console.log('error while creating user',err);
      this.errorMsg = "Error creating user account: " + err;
      returnValue = false;
    }
    return returnValue;
  }
  
  async updateEmail(newEmail) {
    this.processing = true;
    try {
      this.user = await this.user.updateEmail(newEmail);
      this.successMsg = "Successfully changed email and login username for the user account.";
      this.useredit.loadPageData();
      this.processing = false;
    }
    catch(err) {
      this.processing = false;
      this.errorMsg = "Error updating email: " + err['errors'][0]['message'];
      console.log('error while retrieving user account',err);
    }
  }
  
  
  /**
   * html page handlers
   **/
  onSelectTab($event) {
    this.currentPage = $event.tabTitle;
  }

  onBackToUserList() {
      if(this.globalstate.editNewInProgress){
        this.globalstate.routeCallbackThis = this;
        this.globalstate.routeCallback = this.backToUserList;
        this.userConfirmation("unsavedchanges");
      }
      else{
        this.backToUserList();
      }
  }
  
  backToUserList(localThis=this){
    	localThis.router.navigate(['/manageusers']);
  }

  getPage() {
    return this.pagelist[this.currentPage];
  }
   
  async receiveMessage($event) {
    if($event['message'] == 'saveuser') {
      if(this.isEdit()){
        await this.saveUser();
      }
      else{
        await this.createNewUser();
      }
    }
    else if($event['message'] == 'newuser') {
      await this.createNewUser();
    }
    else if($event['message'] == 'editseller'){
    	this.router.navigate(['/editseller', "EDIT",$event['parms']['accountId'],"1"]);
    }
    else if($event['message'] == 'updateemail'){
      await this.updateEmail($event['parms']['email']);
    }
    else if($event['message']=='userrequiredfields'){
      this.globalstate.editNewIsValid = $event['parms']['requiredfields'];
    }
  }  
  
  getUserFullName(){
    var returnValue = "";
    if(this.user.accountId == undefined || this.user.accountId.length == 0){
      returnValue = "New User";
    }
    else {
      returnValue = this.user.getFullName();
    }
    return returnValue;
  }
  
  isEdit(){
    return (this.user.accountId != undefined && this.user.accountId.length > 0)
  }
  
  userConfirmation(type:string) {
    let localThis = this;
    this.dialogService.open(ConfirmdialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          type:type,
          editNewIsValid: this.globalstate.editNewIsValid,
          saveCallbackThis: this.globalstate.saveCallbackThis,
          routeCallbackThis: this.globalstate.routeCallbackThis,
          saveCallback: this.globalstate.saveCallback,
          routeCallback: this.globalstate.routeCallback
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }
  
  async processMsg(event){
  }
  
}
