import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

import { AuctionModelService, AuctionModel, LotModel, BidderModel } from '../../model/auction.model';
import { AddBidderDialogComponent } from '../add-bidder-dialog/add-bidder-dialog.component';
import { AuctionChangeEventType, BidResultCode } from '../../API.service'

@Component({
  selector: 'app-auction-bids-home',
  templateUrl: './auction-bids-home.component.html',
  styleUrls: ['./auction-bids-home.component.scss']
})
export class AuctionBidsHomeComponent implements OnInit {
  errorMsg = "";
  successMsg = "";
  processing: boolean = false;

  _NUMBER_OF_BIDS_TO_DISPLAY          = 20;
  _NUMBER_OF_TOP_LOTS_TO_DISPLAY      = 5;
  _LOTS_SUMMARY_LATEST_BIDS_STATUS    = 1;
  _LOTS_SUMMARY_TOP_BIDS_STATUS       = 2;
  _LOTS_SUMMARY_BOTTOM_BIDS_STATUS    = 3;

  WINNING_BID_STR:string     = "SELECTED BIDDER HAS THE WINNING BID";
  OUT_BID_STR:string         = "SELECTED BIDDER HAS BEEN OUTBID";
  BID_UNSUCESSFUL_STR:string = "BID WAS UNSUCCESSFUL";

  winMsg:string = "";
  outBidMsg:string = "";

  activelotId;
  bidderAccountId;
  activeLot:LotModel = undefined;
  editFloorPrice:boolean = false;
  editIncrement:boolean = false;
  subscriptionKey = undefined;
  lastLotsFilter:number = 1;
  lotSummaryList:LotModel[] = [];

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private dialogService: NbDialogService,
              public auctionModel: AuctionModelService) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.lastLotsFilter = 1;
    this.winMsg = "";
    this.outBidMsg = "";
  }

  ngOnDestroy(){
    this.auctionModel.unsubscribeToSingleBidUpdate(this.subscriptionKey);
  }

  /**
   * server based api calls
   **/
  async loadLot(lot:LotModel){
    this.processing = true;
    try {
      this.activeLot = await this.auctionModel.loadLot(lot.auctionId,lot.lotId);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving lot',err);
    }
  }

  async loadBidders(){
    try {
      await this.auctionModel.activeAuction.loadBidders();
    }
    catch(err){
      console.log('error while loading bidders:',err);
    }
  }

  async updatePriceParameters(){
    this.processing = true;
    try {
      await this.activeLot.updateLotPriceParms(this.getFloorPriceValue(),this.getIncrementValue());
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while updating price parameters',err);
    }
  }

  async nextBids(){
    try {
      await this.activeLot.getNextBids(this._NUMBER_OF_BIDS_TO_DISPLAY);
    }
    catch(err){
      console.log('error while retrieving bids',err);
    }
  }

  async prevBids(){
    try {
      await this.activeLot.getPreviousBids(this._NUMBER_OF_BIDS_TO_DISPLAY);
    }
    catch(err){
      console.log('error while retrieving bids',err);
    }
  }

  async proxyBidNow(){
    this.processing = true;
    this.errorMsg = "";

    try {
      await this.activeLot.proxyBidNow(this.bidderAccountId);
      this.processing = false;
      this.processBidResponse();
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "System error while submitting bid.";
      console.log('error while bidding',err);
    }
  }

  async loadLotsWithMostRecentBidActivity(){
    try {
      this.lotSummaryList = new Array();
      this.lotSummaryList = await this.auctionModel.loadAuctionsLotsByRecentBidActivity(
        this.auctionModel.activeAuction.auctionId,
        this._NUMBER_OF_TOP_LOTS_TO_DISPLAY,);
      // console.log('lotSummaryList',this.lotSummaryList);
    }
    catch(err){
      console.log('error while retrieving lots with most recent bid activity',err);
    }
  }

  async loadLotsPrice(top:boolean=true){
    try {
      this.lotSummaryList = new Array();
      let sortDirection = top ? "DESC" : "ASC";
      this.lotSummaryList = await this.auctionModel.loadAuctionsLotsByRecentBidPrice(
        this.auctionModel.activeAuction.auctionId,
        this._NUMBER_OF_TOP_LOTS_TO_DISPLAY,
        sortDirection);
    }
    catch(err){
      console.log('error while retrieving lots with most recent bid activity',err);
    }
  }

  /**
   * html page handlers
   **/
  async onSelectedLotChange(event){
    this.winMsg = "";
    this.outBidMsg = "";
    let lot = this.auctionModel.activeAuction.lots.find((el)=>{return el.lotId==event});
    await this.loadLot(lot);
    this.onSelectedBidderChange(this.bidderAccountId);
    await this.nextBids();
  }

  onPreviousBidders(){
    this.prevBids();
  }

  onNextBidders(){
    this.nextBids();
  }

  previousBidsAvail(){
    return this.activeLot != undefined && this.activeLot.previousBidsAvailable();
  }

  nextBidsAvail(){
    return this.activeLot != undefined && this.activeLot.moreBidsAvailable();
  }

  onEditFloorPrice(){
    this.activeLot.floorPrice = this.getFloorPriceValue();
    this.editFloorPrice = true;
  }

  onEditBidIncrement(){
    this.activeLot.bidIncrement = this.getIncrementValue();
    this.editIncrement = true;
  }

  onCancelEditFloorPrice(){
    this.editFloorPrice = false;
  }

  onUpdateFloorPrice(){
    this.updatePriceParameters();
    this.editFloorPrice = false;
  }

  onCancelEditBidIncrement(){
    this.editIncrement = false;
  }

  onUpdateBidIncrement(){
    this.updatePriceParameters();
    this.editIncrement = false;
  }

  async onFilterByLotsStatus(status,forceRefresh:boolean=false){
    if(status == this.lastLotsFilter && forceRefresh==false){
      return;
    }
    else{
      this.lastLotsFilter = status;
    }
    if(this.lastLotsFilter == this._LOTS_SUMMARY_LATEST_BIDS_STATUS){
      this.loadLotsWithMostRecentBidActivity()
    }
    else if(this.lastLotsFilter == this._LOTS_SUMMARY_TOP_BIDS_STATUS){
      this.loadLotsPrice(true);
    }
    else if(this.lastLotsFilter == this._LOTS_SUMMARY_BOTTOM_BIDS_STATUS){
      this.loadLotsPrice(false);
    }
  }

  onBid(){
    if(this.bidderAccountId != undefined){
      this.proxyBidNow();
    }
  }

  onNewBidder(){
    let localThis = this;
    this.dialogService.open(AddBidderDialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  onSelectedBidderChange(event){
    if(this.activeLot.bidderAccountId != undefined &&
         event != undefined &&
         this.activeLot.bidderAccountId.length > 0 &&
         event.length > 0 &&
         this.activeLot.bidderAccountId == event){
      this.winMsg = this.WINNING_BID_STR;
      this.outBidMsg = "";
    }
    else{
      this.winMsg = ""
      this.outBidMsg = "";
    }
  }

  /**
   * other
   **/
  loadPageData(){
    this.activelotId = undefined;
    if(this.auctionModel.activeAuction.lots != undefined && this.auctionModel.activeAuction.lots.length >0){
      this.activelotId = this.auctionModel.activeAuction.lots[0].lotId;
      this.onSelectedLotChange(this.activelotId);
    }
    this.loadBidders();
    this.loadLotsWithMostRecentBidActivity();
    this.startPolling();
  }

  getStartingBid(){
    var returnValue = "";
    if(this.activeLot != undefined && this.activeLot.startBid != undefined && this.activeLot.startBid > 0){
      returnValue = "$" + this.activeLot.startBid.toString();
    }
    else if(this.auctionModel != undefined && this.auctionModel.activeAuction.startBid != undefined && this.auctionModel.activeAuction.startBid > 0){
      returnValue = "$" + this.auctionModel.activeAuction.startBid.toString();
    }
    return returnValue;
  }

  getFloorPriceValue():number{
    var returnValue:number = 0;
    if(this.activeLot != undefined && this.activeLot.floorPrice != undefined && this.activeLot.floorPrice > 0){
      returnValue = this.activeLot.floorPrice;
    }
    else if(this.auctionModel != undefined && this.auctionModel.activeAuction.floorPrice != undefined && this.auctionModel.activeAuction.floorPrice > 0){
      returnValue = this.auctionModel.activeAuction.floorPrice;
    }
    return returnValue;
  }

  getFloorPrice(){
    let returnValue = "$" + this.getFloorPriceValue().toString();
    return returnValue;
  }

  getIncrementValue():number{
    var returnValue:number = 0;
    if(this.activeLot != undefined && this.activeLot.bidIncrement != undefined && this.activeLot.bidIncrement > 0){
      returnValue = this.activeLot.bidIncrement;
    }
    else if(this.auctionModel != undefined && this.auctionModel.activeAuction.bidIncrement != undefined && this.auctionModel.activeAuction.bidIncrement > 0){
      returnValue = this.auctionModel.activeAuction.bidIncrement;
    }
    return returnValue;
  }

  getIncrement(){
    let returnValue = "$" + this.getIncrementValue().toString();
    return returnValue;
  }

  getBidderCount(){
    var returnValue = 0;
    if(this.activeLot != undefined && this.activeLot.bidderCount != undefined && this.activeLot.bidderCount > 0){
      returnValue = this.activeLot.bidderCount;
    }
    return returnValue;
  }

  getBidCount(){
    var returnValue = 0;
    if(this.activeLot != undefined && this.activeLot.bidCount != undefined && this.activeLot.bidCount > 0){
      returnValue = this.activeLot.bidCount;
    }
    return returnValue;

  }

  getBidStr(){
    var returnValue = "";
    if(this.activeLot != undefined && this.activeLot.nextBid != undefined ){
      returnValue = " BID $" + this.activeLot.nextBid;
    }
    else if(this.activeLot != undefined && this.activeLot.nextBid == undefined && this.activeLot.startBid != undefined){
      returnValue = " BID $" + this.activeLot.startBid;
    }

    return returnValue;
  }


  async startPolling(){
    try {
      var localThis = this;
      var subscription = await this.auctionModel.subscribeToAuctionUpdates(this.auctionModel.activeAuction.auctionId,localThis,this.handleAuctionUpdateNotification);
      this.subscriptionKey = subscription['key'];
    }
    catch(err){
      this.errorMsg = "System error while starting bid subscription.";
      console.log('error while starting bid subscription',err);
    }
  }

  async handleAuctionUpdateNotification(updatedData, localThis){
    // console.log('handleAuctionUpdateNotification',updatedData);
    if ([AuctionChangeEventType.LOT, AuctionChangeEventType.ALL].includes(updatedData.eventType)) {
      const bidData = updatedData.lotData;

      if(localThis.activeLot.lotId==bidData.lotId){
        localThis.activeLot.currentBid = bidData.currentBid;
        localThis.activeLot.bidCount = bidData.bidCount;
        localThis.activeLot.bidIncrement = bidData.bidIncrement;
        localThis.activeLot.bidderId = bidData.bidderId;
        localThis.activeLot.nextBid = bidData.nextBid;
        localThis.activeLot.bidderAccountId = bidData.bidderAccountId;
        localThis.activeLot.biddingDisabled = bidData.biddingDisabled;
        localThis.activeLot.bidderCount = bidData.bidderCount;
      }

      if(localThis.activeLot.lotId == bidData.lotId &&
         localThis.bidderAccountId != undefined &&
         bidData.bidderAccountId != undefined &&
         localThis.bidderAccountId == bidData.bidderAccountId){
        localThis.winMsg = localThis.WINNING_BID_STR;
        localThis.outBidMsg = "";
      }
      else if(localThis.activeLot.lotId == bidData.lotId &&
              localThis.bidderAccountId != undefined &&
              bidData.bidderAccountId != undefined &&
              localThis.bidderAccountId != bidData.bidderAccountId){
        localThis.winMsg = "";
        localThis.outBidMsg = localThis.OUT_BID_STR;
      }

      localThis.activeLot.currentBiddingPage = undefined;

      await localThis.nextBids();
      await localThis.onFilterByLotsStatus(localThis.lastLotsFilter,true);

      let bidderIndex = localThis.auctionModel.activeAuction.bidders.findIndex((el)=>{ return el.bidderAccountId == bidData.bidderAccountId });
      if(bidderIndex < 0){
        await localThis.loadBidders();
      }

      if(bidderIndex < 0 && localThis.activeLot.lotId == bidData.lotId){
        await localThis.activeLot.loadCounts();
      }

    }
    if ([AuctionChangeEventType.AUCTION, AuctionChangeEventType.ALL].includes(updatedData.eventType)) {
      const activeAuction = localThis.auctionModel.activeAuction;
      const auctionData = updatedData.auctionData;

      if (activeAuction.auctionId === updatedData.auctionId) {
        Object.keys(auctionData).forEach(key => activeAuction[key] = auctionData[key] );
      }
      const auctionToUpdate = localThis.auctionModel.openAuctionsList.find(auction => auction.auctionId === updatedData.auctionId);
      if (auctionToUpdate) {
        Object.keys(auctionData).forEach(key => auctionToUpdate[key] = auctionData[key]);
      }

      localThis.messageEvent.emit({ message: "auction-updated" });
    }
  }

  hasBidders(){
    return (this.auctionModel.activeAuction.bidders != undefined && this.auctionModel.activeAuction.bidders.length > 0);
  }

  processBidResponse(){
    this.winMsg    = "";
    this.outBidMsg = "";

    if(this.activeLot.code == BidResultCode.BID_SUCCESS){
      this.winMsg = this.WINNING_BID_STR;
    }
    else if(this.activeLot.code == BidResultCode.BID_TOO_LOW){
      this.outBidMsg = this.OUT_BID_STR;
    }
    else{
      this.outBidMsg = this.activeLot.message;
    }
  }

  getStatus(status){
    return this.lastLotsFilter == status ? "primary" : "basic";
  }

  getBidder(lot){
    let returnValue = "";
    if(this.auctionModel.activeAuction.bidders != undefined){
      let bidder = this.auctionModel.activeAuction.bidders.find((el)=>{ return el.bidderAccountId == lot.bidderAccountId });
      if(bidder != undefined){
        returnValue = bidder.bidderLastName != undefined ? bidder.bidderLastName : "";
      }
    }
    return returnValue;
  }

  biddingOpen(){
    var lotBiddingDisabled = (this.activeLot != undefined && this.activeLot.biddingDisabled != undefined) ? this.activeLot.biddingDisabled : true;
    return !lotBiddingDisabled;
  }

  isAuctionLive(){
    return this.auctionModel.activeAuction.isLive();
  }

  processMsg(event){
    if(event != undefined){
      var msg = event['message'];
      if(msg== "closeuserselection"){
        let userList = event['parms'];
        for(let item of userList){
          let bidderExistsIndex = this.auctionModel.activeAuction.bidders.findIndex((el)=>{return el.bidderAccountId == item.accountId});
          if(bidderExistsIndex < 0){
            let bidderModel = new BidderModel();
            bidderModel.auctionId = this.activeLot.auctionId;
            bidderModel.lotId = "";
            bidderModel.bidderFirstName = item.givenName;
            bidderModel.bidderLastName = item.familyName;
            bidderModel.bidderAccountId = item.accountId;
            this.auctionModel.activeAuction.bidders.push(bidderModel);
          }
        }
      }
    }
  }
  
  auctionIsLive(){
    var returnValue = this.auctionModel.activeAuction != undefined ? 
      this.auctionModel.activeAuction.isLive() || this.auctionModel.activeAuction.isClosed() || this.auctionModel.activeAuction.isSettled() : false;
    return returnValue;
  }
  
}
