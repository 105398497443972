import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortalUser } from '../../model/portaluser.model';
import { States } from '../../auth/states';


@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  streetAddressOne:string;
  streetAddressTwo:string;

  @Input() user:PortalUser;
  @Output() messageEvent = new EventEmitter<any>();
  associatedBusinesses = [];
  emailAddress:string = ""
  changeemail:boolean = false;
  states:string[] = [];
  
  constructor() { }

  ngOnInit(): void {
      this.states = States.states;
      this.loadPageData();
  }
  
  /**
   * html page handlers
   **/
  onSave() {
    this.saveAddress();
    var eventMsg = {}
    eventMsg['message'] = 'saveuser';
    this.messageEvent.emit(eventMsg);
  }
  
  onEditSeller(accountId){
    var eventMsg = {}
    eventMsg['message'] = 'editseller';
    eventMsg['parms'] = {
      "accountId":accountId
    };
    this.messageEvent.emit(eventMsg);
  }
  
  onUpdateEmail(){
    var eventMsg = {}
    eventMsg['message'] = 'updateemail';
    eventMsg['parms'] = {
      "email":this.emailAddress
    };
    this.messageEvent.emit(eventMsg);
    this.changeemail = false;
  }
  
  changeEmail(){
    this.emailAddress = this.user.email;
    this.changeemail = true;
  }

  cancelChangeEmail(){
    this.changeemail = false;
  }
  
  
  /**
   * other
   **/
   loadPageData(){
     this.loadAddress();
     this.associatedBusinesses = this.user.associatedBusinesses != undefined ? this.user.associatedBusinesses : []; 
   }
  
  saveAddress(){
    this.user.physicalAddress = [];
    if(this.streetAddressOne != undefined && this.streetAddressOne.length > 0){
      this.user.physicalAddress.push(this.streetAddressOne);
    }

    if(this.streetAddressTwo != undefined && this.streetAddressTwo.length > 0){
      this.user.physicalAddress.push(this.streetAddressTwo);
    }
    
  }

  loadAddress(){
    this.streetAddressOne = (this.user.physicalAddress != undefined &&
                             this.user.physicalAddress[0] != undefined) ?
                             this.user.physicalAddress[0] : "";
                             
    this.streetAddressTwo = (this.user.physicalAddress != undefined &&
                             this.user.physicalAddress[1] != undefined) ?
                             this.user.physicalAddress[1] : "";
  }
  
  validZip(){
    var returnValue = true;
    if(this.user.physicalZip != undefined && this.user.physicalZip.length > 0){
      returnValue = /^\d{5}/g.test(this.user.physicalZip);
    }
    return returnValue;
  }

  validPhone(){
    var returnValue = false;
    if(this.user.phone != undefined && this.user.phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(this.user.phone);
    }
    return returnValue;
  }
  
  pageDataValid(){
    var returnValue = (this.validPhone() && 
                       this.user.givenName != undefined &&
                       this.user.givenName.length > 0 &&
                       this.user.familyName != undefined &&
                       this.user.familyName.length > 0 &&
                       this.validZip());
                       
    var eventMsg = {}
    eventMsg['message'] = 'userrequiredfields';
    eventMsg['parms'] = {
      'requiredfields':returnValue
    };
    this.messageEvent.emit(eventMsg);
                       
    return returnValue;
  }
  
  validEmail() {
    var returnValue = true;
    if(this.emailAddress != undefined && this.emailAddress.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.emailAddress).toLowerCase());
      
    }
    return returnValue;
  }  
  
  isEdit(){
    return (this.user.accountId != undefined && this.user.accountId.length > 0)
  }
  
  onClearState(){
    this.user.physicalState = null;
  }
  

}
