import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuctionStatus, PaymentStatus } from 'src/app/API.service';
import { AuctionModelService, AuctionReportLineItem } from 'src/app/model/auction.model';
import moment from 'moment';
import { environment } from '../../../environments/environment';
interface AuctionReportGroupListing {
  [sellerKey: string]: {
    name: string;
    bidders: AuctionReportBidder
  }
}

interface AuctionReportBidder {
  [bidderKey: string]: {
    name: string;
    paymentStatus: PaymentStatus;
    invoiceDateSent: string;
    lineItems: AuctionReportLineItem[];
  }
}
@Component({
  selector: 'app-auction-report-sold-lot-group',
  templateUrl: './auction-report-sold-lot-group.component.html',
  styleUrls: ['./auction-report-sold-lot-group.component.scss']
})
export class AuctionReportSoldLotGroupComponent implements OnInit {

  @Input() soldLineItems: AuctionReportLineItem[];

  public groupListing: AuctionReportGroupListing = {};

  public paymentStatusOptions: any = [
    { key: 'UNPAID', value: 'Unpaid' },
    { key: 'DIRECT_PAID', value: 'Direct-Paid' },
    { key: 'PAID_SALE_REP', value: 'Paid Sale Rep' },
    { key: 'UNCOLLECTIBLE', value: 'Uncollectible' },
  ];

  public loading: { [combinedSellerBuyerKey: string]: boolean } = {};

  constructor(public auctionModel: AuctionModelService) { }

  ngOnInit(): void {
    this.groupListing = this.groupAuctionReportBySellerAndBidder();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.soldLineItems && changes.soldLineItems.currentValue !== changes.soldLineItems.previousValue) {
      this.groupListing = this.groupAuctionReportBySellerAndBidder();
      // console.log("ngOnChanges:groupListing",this.groupListing);
    }
  }

  public sellerKeys() {
    return Object.keys(this.groupListing);
  }

  public buyerKeys(sellerKey: string) {
    if (!this.groupListing.hasOwnProperty(sellerKey)) return {};

    return Object.keys(this.groupListing[sellerKey].bidders);
  }

  private groupAuctionReportBySellerAndBidder(): AuctionReportGroupListing {
    var result = {} as AuctionReportGroupListing;

    this.soldLineItems.forEach(item => {
      const sellerKey = item.sellerEmail || "Unknown";
      const bidderKey = item.winningBidderEmail || "Unknown";
      if (!result.hasOwnProperty(sellerKey)) {
        result[sellerKey] = {
          name: item.sellerName,
          bidders: {} as AuctionReportBidder
        }
      }
      const bidders = result[sellerKey].bidders;
      if (!bidders.hasOwnProperty(bidderKey)) {
        bidders[bidderKey] = {
          name: `${item.winningBidderFirstName ? item.winningBidderFirstName : ""} ${item.winningBidderLastName ? item.winningBidderLastName : ""}`,
          paymentStatus: item.paymentStatus,
          invoiceDateSent: item.invoice && item.invoice.invoiceSentDate || null,
          lineItems: []
        }
      }
      bidders[bidderKey].lineItems.push(item);
    });

    return result;
  }

  public async onUpdatePaymentStatus(sellerKey: string, buyerKey: string) {
    const itemsToUpdate = this.groupListing[sellerKey].bidders[buyerKey].lineItems;
    const that = this;

    const updatePaymentStatuses = async() => {
      const promises = itemsToUpdate.map(async item => {
        let result = {};
        const actualLineItem = that.auctionModel.activeAuctionReport.lineItems.find(x => x.lotId === item.lotId);
        if (actualLineItem) {
          actualLineItem.paymentStatus = this.groupListing[sellerKey].bidders[buyerKey].paymentStatus;
          result = await that.auctionModel.activeAuctionReport.updatePaymentStatus(actualLineItem);
        }
        return result;
      })
      await Promise.all(promises);
    }
    updatePaymentStatuses();
  }

  public async onSendInvoice(sellerKey: string, buyerKey: string) {
    this.loading[sellerKey + buyerKey] = true;
    const result = await this.auctionModel.activeAuctionReport.sendInvoice(sellerKey, buyerKey);
    const invoice = result.invoice;
    const itemsToUpdate = this.groupListing[sellerKey].bidders[buyerKey].lineItems;

    itemsToUpdate.forEach(item => {
      const lineItem = this.auctionModel.activeAuctionReport.lineItems.find(x => x.lotId === item.lotId);
      if (lineItem) {
        lineItem.invoice = invoice;
        lineItem.invoiceId = invoice.invoiceId;
      }
    });
    this.loading[sellerKey + buyerKey] = false;
  }

  public formatAsMonthYear(dateString: string): string {
    const momentDate = moment(dateString);
    if (!momentDate.isValid()) return dateString;
    return momentDate.format("MM/DD hh:mma");
  }

  public isLoading(sellerKey: string, buyerKey: string): boolean {
    return this.loading[buyerKey + sellerKey] || false;
  }

  public loadingText(text: string, buyerKey: string, sellerKey: string) {
    return this.isLoading(sellerKey, buyerKey) ? "" : text;
  }

  public onViewInvoice(invoiceId: string) {
    window.open(
      `https://${environment.awsconfig.buyer_portal_path_url}/invoice/${invoiceId}`,
      '_blank'
    );
  }

  public invoiceNotCreatedYet(sellerKey: string, buyerKey: string) {
    return this.groupListing[sellerKey].bidders[buyerKey].lineItems.length > 0 && !this.groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId;
  }

  public invoiceNotAutomatedPaidYet(sellerKey: string, buyerKey: string) {
    return this.groupListing[sellerKey].bidders[buyerKey].paymentStatus != PaymentStatus.AUTOMATED_PAID;
  }

  public isAuctionSettled() {
    return this.auctionModel.activeAuction.status === AuctionStatus.SETTLED;
  }

  public sendInvoiceEnabled(sellerKey: string, buyerKey: string) {
    return this.invoiceNotCreatedYet(sellerKey, buyerKey) && !this.isAuctionSettled();
  }

  public getAddress(physicalAddress) {
    var returnValue = "";
    if(physicalAddress != undefined) {
      for(let address of physicalAddress) {
        returnValue = returnValue + address + " ";
      }
      if(returnValue.replace(/ /g,'').length == 0) {
        returnValue = "";
      }
    }
    return returnValue;
  }

  public getWinningBidderPhoneNumber(lineItem) {
    return lineItem.winningBidderPhoneNumber != undefined ? lineItem.winningBidderPhoneNumber : "";
  }

  public getWinningBidderEmail(lineItem) {
    return lineItem.winningBidderEmail != undefined ? lineItem.winningBidderEmail : "";
  }
}

