import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule,  } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { SortablejsModule } from 'ngx-sortablejs';

// import { RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly
import { NbThemeModule } from '@nebular/theme';
import { NbLayoutModule } from '@nebular/theme';
import { NbTabsetModule } from '@nebular/theme';
import { NbButtonModule } from '@nebular/theme';
import { NbSpinnerModule } from '@nebular/theme';
import { NbCardModule } from '@nebular/theme';
import { NbCheckboxModule } from '@nebular/theme';
import { NbTooltipModule } from '@nebular/theme';
import { NbSelectModule } from '@nebular/theme';
import { NbInputModule } from '@nebular/theme';
import { NbMenuModule } from '@nebular/theme';
import { NbDialogModule } from '@nebular/theme';
import { NbContextMenuModule } from '@nebular/theme';
import { NbToggleModule } from '@nebular/theme';
import { NbPopoverModule } from '@nebular/theme';
import { NbFormFieldModule } from '@nebular/theme';
import { NbIconModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbStepperModule } from '@nebular/theme';
import { NbDatepickerModule } from '@nebular/theme';
import { NbCalendarModule } from '@nebular/theme';
import { NbRadioModule } from '@nebular/theme';
import { NbAutocompleteModule } from '@nebular/theme';

import { LMarkdownEditorModule } from 'ngx-markdown-editor';

import { AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import {Amplify} from '@aws-amplify/core';
import API from '@aws-amplify/api';
import Interactions from '@aws-amplify/interactions';
import Storage from '@aws-amplify/storage';

import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './services/auth.service';
import { AuthGuard, AuthAdminGuard } from './services/auth-guard.service';

import { PortalUser, AccountService} from './model/portaluser.model';
import { SellerService} from './model/seller.model';
import { AuctionModelService } from './model/auction.model';
import { FileTransferService } from './services/filetransfer.service';
import { VisibilityService } from './services/visibility.service';
import { GlobalStateService } from './services/globalstate.service';

import { environment } from '../environments/environment';
import { DndDirective } from './dnd/dnd.directive';
import { SigninComponent } from './auth/signin/signin.component';
import { AuthhomeComponent } from './auth/authhome/authhome.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ChangepasswordComponent } from './auth/changepassword/changepassword.component';
import { ForgotpasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { SettingshomeComponent } from './accounts/settingshome/settingshome.component';
import { AccountsettingsComponent } from './accounts/accountsettings/accountsettings.component';
import { SettingssidebarComponent } from './accounts/settingssidebar/settingssidebar.component';
import { BidsandsavesComponent } from './accounts/bidsandsaves/bidsandsaves.component';
import { FollowingComponent } from './accounts/following/following.component';
import { MysalesComponent } from './accounts/mysales/mysales.component';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { VideoplayerComponent } from './videoplayer/videoplayer.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StatuscardComponent } from './statuscard/statuscard.component';
import { MenucardComponent } from './menucard/menucard.component';
import { ManageauctionsComponent } from './auctions/manageauctions/manageauctions.component';
import { ManagesellersComponent } from './sellers/managesellers/managesellers.component';
import { ManageusersComponent } from './users/manageusers/manageusers.component';
import { AuctionslistComponent } from './auctions/auctionslist/auctionslist.component';
import { AuctiondetailComponent } from './auctions/auctiondetail/auctiondetail.component';
import { SellerslistComponent } from './sellers/sellerslist/sellerslist.component';
import { SellerdetailComponent } from './sellers/sellerdetail/sellerdetail.component';
import { UserslistComponent } from './users/userslist/userslist.component';
import { UserdetailComponent } from './users/userdetail/userdetail.component';
import { AuctionedithomeComponent } from './auctions/auctionedithome/auctionedithome.component';
import { AuctionstatusflowComponent } from './auctions/auctionstatusflow/auctionstatusflow.component';
import { AuctionsaleinfoComponent } from './auctions/auctionsaleinfo/auctionsaleinfo.component';
import { AuctionconfigureComponent } from './auctions/auctionconfigure/auctionconfigure.component';
import { AuctionreportComponent } from './auctions/auctionreport/auctionreport.component';
import { FileChooserComponent } from './file-chooser/file-chooser.component';
import { LotFileChooserComponent } from './lot-file-chooser/lot-file-chooser.component';
import { SelleredithomeComponent } from './sellers/selleredithome/selleredithome.component';
import { SellerinfoComponent } from './sellers/sellerinfo/sellerinfo.component';
import { SellerinfodetailsComponent } from './sellers/sellerinfodetails/sellerinfodetails.component';
import { SellerinfosalesComponent } from './sellers/sellerinfosales/sellerinfosales.component';
import { AuctionReportLotListComponent } from './auctions/auction-report-lot-list/auction-report-lot-list.component';
import { UserselectiondialogComponent } from './sellers/userselectiondialog/userselectiondialog.component';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { AuctionBidsHomeComponent } from './auctions/auction-bids-home/auction-bids-home.component';
import { LotDetailDescComponent } from './auctions/lot-detail-desc/lot-detail-desc.component';
import { AuctionBidderListComponent } from './auctions/auction-bidder-list/auction-bidder-list.component';
import { AuctionBidderDetailComponent } from './auctions/auction-bidder-detail/auction-bidder-detail.component';
import { LotCardComponent } from './auctions/lot-card/lot-card.component';
import { AddBidderDialogComponent } from './auctions/add-bidder-dialog/add-bidder-dialog.component';
import { SelectUsersListComponent } from './users/select-users-list/select-users-list.component';
import { SelectUserDetailComponent } from './users/select-user-detail/select-user-detail.component';
import { AuctionReportLotDetailComponent } from './auctions/auction-report-lot-detail/auction-report-lot-detail.component';
import { AuctionReportAdjustDialogComponent } from './auctions/auction-report-adjust-dialog/auction-report-adjust-dialog.component';
import { AuctionReportUpdateWinningBuyerDialogComponent } from './auctions/auction-report-update-winning-buyer-dialog/auction-report-update-winning-buyer-dialog.component';
import { AuctionLotListComponent } from './auctions/auction-lot-list/auction-lot-list.component';
import { AuctionLotDetailComponent } from './auctions/auction-lot-detail/auction-lot-detail.component';
import { AuctionLotsHomeComponent } from './auctions/auction-lots-home/auction-lots-home.component';
import { AuctionLotEditHomeComponent } from './auctions/auction-lot-edit-home/auction-lot-edit-home.component';
import { LotLineageComponent } from './auctions/lot-lineage/lot-lineage.component';
import { LotEpdsComponent } from './auctions/lot-epds/lot-epds.component';
import { UserDetailsHomeComponent } from './users/user-details-home/user-details-home.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { AuctionTimeRemainingCounterComponent } from './auctions/auction-time-remaining-counter/auction-time-remaining-counter.component';
import { AuctionReportSoldLotGroupComponent } from './auctions/auction-report-sold-lot-group/auction-report-sold-lot-group.component';
import { AuctionReportPartialPaymentDialogComponent } from './auctions/auction-report-partial-payment-dialog/auction-report-partial-payment-dialog.component';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { EventService } from './services/event.service';
import { NotFoundComponent } from './not-found/not-found.component';


Amplify.configure(environment.awsconfig);
Auth.configure(environment.awsconfig);
API.configure(environment.awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    DndDirective,
    SigninComponent,
    AuthhomeComponent,
    SignupComponent,
    ChangepasswordComponent,
    ForgotpasswordComponent,
    SettingshomeComponent,
    AccountsettingsComponent,
    SettingssidebarComponent,
    BidsandsavesComponent,
    FollowingComponent,
    MysalesComponent,
    VideoplayerComponent,
    DashboardComponent,
    StatuscardComponent,
    MenucardComponent,
    ManageauctionsComponent,
    ManagesellersComponent,
    ManageusersComponent,
    AuctionslistComponent,
    AuctiondetailComponent,
    SellerslistComponent,
    SellerdetailComponent,
    UserslistComponent,
    UserdetailComponent,
    AuctionedithomeComponent,
    AuctionstatusflowComponent,
    AuctionsaleinfoComponent,
    AuctionconfigureComponent,
    AuctionreportComponent,
    FileChooserComponent,
    LotFileChooserComponent,
    SelleredithomeComponent,
    SellerinfoComponent,
    SellerinfodetailsComponent,
    SellerinfosalesComponent,
    AuctionReportLotListComponent,
    UserselectiondialogComponent,
    ConfirmdialogComponent,
    AuctionBidsHomeComponent,
    LotDetailDescComponent,
    AuctionBidderListComponent,
    AuctionBidderDetailComponent,
    LotCardComponent,
    AddBidderDialogComponent,
    SelectUsersListComponent,
    SelectUserDetailComponent,
    AuctionReportLotDetailComponent,
    AuctionReportAdjustDialogComponent,
    AuctionReportUpdateWinningBuyerDialogComponent,
    AuctionLotListComponent,
    AuctionLotDetailComponent,
    AuctionLotsHomeComponent,
    AuctionLotEditHomeComponent,
    LotLineageComponent,
    LotEpdsComponent,
    UserDetailsHomeComponent,
    UserEditComponent,
    AuctionTimeRemainingCounterComponent,
    AuctionReportSoldLotGroupComponent,
    AuctionReportPartialPaymentDialogComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NbLayoutModule,
    NbThemeModule.forRoot({ name: 'g2live' }),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbContextMenuModule,
    NbTabsetModule,
    NbButtonModule,
    NbSpinnerModule,
    NbCardModule,
    NbCheckboxModule,
    NbTooltipModule,
    BrowserAnimationsModule,
    NbSelectModule,
    NbInputModule,
    NbToggleModule,
    NbPopoverModule,
    NbFormFieldModule,
    NbIconModule,
    NbEvaIconsModule,
    HttpClientModule,
    NbStepperModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgImageSliderModule,
    NbDatepickerModule,
    NbCalendarModule,
    NbRadioModule,
    LMarkdownEditorModule,
    SortablejsModule.forRoot({animation: 150}),
    NgxMaskModule.forRoot(),
    NbAutocompleteModule
  ],
  entryComponents: [
    SigninComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthAdminGuard,
    FileTransferService,
    AuctionModelService,
    EventService,
    PortalUser,
    AccountService,
    SellerService,
    GlobalStateService,
    VisibilityService,
    {
      provide: AmplifyService,
      useFactory:  () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions
        });
      }
    },

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
