<div class="container-fluid" *ngIf="isAuction()">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-3 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ auction.businessName.length > 0 ? auction.businessName : '-' }} </span></div>
	    </div>
    	
	    <div class="col-md-3 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ auction.title }} </span></div>
	    </div>
	    
	    <div class="col-md-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ startDate }} </span></div>
	    </div>

	    <div class="col-md-2 mt-3 mb-3 flex-center">
	      <button type="button" [ngClass]="getStatusButtonStyle()" *ngIf="getAuctionStatus() != undefined && getAuctionStatus().length > 0">{{ getAuctionStatus() }}</button>
	    </div>

	    <div class="col-md-1 mt-3 mb-3 justify-content-center align-self-center">
	      <div>
            <nb-icon icon="edit-outline" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onViewDetails()">Details</a>
	      </div>
	    </div>

	    <div class="col-md-1 mt-3 mb-3">
	      <div>
            <button nbButton size="small" status="primary" outline (click)="onClone()">+ CLONE</button>
	      </div>
	    </div>

    </div> <!-- header -->
</div>

<div class="container-fluid" *ngIf="isSeller()">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-4 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ auction.title }} </span></div>
	    </div>
	    
	    <div class="col-md-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ startDate }} </span></div>
	    </div>

	    <div class="col-md-2 mt-3 mb-3 flex-center">
	      <button type="button" [ngClass]="getStatusButtonStyle()" *ngIf="getAuctionStatus() != undefined && getAuctionStatus().length > 0">{{ getAuctionStatus() }}</button>
	    </div>

	    <div class="col-md-2 mt-3 mb-3 justify-content-center align-self-center">
	      <div>
            <nb-icon icon="edit-outline" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onViewDetails()">Details</a>
	      </div>
	    </div>

	    <div class="col-md-2 mt-3 mb-3">
	      <div>
            <button nbButton size="small" status="primary" outline (click)="onClone()">+ CLONE</button>
	      </div>
	    </div>

    </div> <!-- header -->
</div>
