
<nav class="navbar navbar-expand-sm navbar-light border-bottom">
   <a class="navbar-brand logo-link" routerLink="/">
    <img src="../../assets/brand/logo.svg" alt="Angus Live Admin Logo">
    <span class="ml-2">v0.2.1</span>
  </a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <!-- wide -->
    <span class="ml-auto d-sm-block d-none">
      <ul class="navbar-nav navbar-right" *ngIf="authService.isAdmin()">
          <li class="header-nav">
            <a class="header-nav-link" routerLink="/managesellers" routerLinkActive="header-active">Manage Sellers</a>
          </li>
          <li class="header-nav ml-md-3">
            <a class="header-nav-link" [routerLink]="['/manageauctions', '0']" routerLinkActive="header-active">Manage Auctions</a>
          </li>
          <li class="header-nav ml-md-3">
            <a class="header-nav-link" routerLink="/manageusers" routerLinkActive="header-active">Manage Site</a>
          </li>

          <li class="dropdown ml-md-5">
              <a class="dropdown-toggle header-nav-link ml-3 my-2 my-sm-0" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="header-nav-link">Hi, {{ getFirstName() }}</span>
              </a>
              <div class="dropdown-menu dropdown-link" aria-labelledby="navbarDropdown">
                <a class="dropdown-item dropdown-link" [routerLink]="['/accountsettings', '1']"><span> Account Settings</span></a>
                <a class="dropdown-item dropdown-link" (click)="onLogout()"><span> Sign Out</span></a>
              </div>
          </li>
      </ul>

      <!-- unauthenticated -->
      <ul class="navbar-nav navbar-right" *ngIf="!authService.isAuthenticated() || !authService.isAdmin()">
          <li class="header-nav">
              <button nbButton status="basic" class="ml-3 my-2 my-sm-0 sign-in-button" (click)="onSignin()">SIGN IN</button>
          </li>
      </ul>
    </span>

    <!-- narrow -->
    <span class="ml-auto d-sm-none">
      <ul class="navbar-nav navbar-right" *ngIf="authService.isAdmin()">
          <li class="header-nav">
            <a class="header-nav-link" routerLink="/managesellers">Manage Sellers</a>
          </li>
          <li class="header-nav ml-3">
            <a class="header-nav-link" [routerLink]="['/manageauctions', '1']">Manage Auctions</a>
          </li>
          <li class="header-nav ml-3">
            <a class="header-nav-link" routerLink="/manageusers">Manage Site</a>
          </li>
          <li class="header-nav ml-3">
            <a class="header-nav-link" [routerLink]="['/accountsettings', '1']">Account Settings</a>
          </li>
          <li class="header-nav ml-3">
            <a class="header-nav-link" routerLink="" (click)="onLogout()">Sign Out</a>
          </li>
      </ul>

      <!-- unauthenticated -->
      <ul class="navbar-nav navbar-right" *ngIf="!authService.isAuthenticated() || !authService.isAdmin()">
          <li class="header-nav">
              <button nbButton status="primary" class="ml-3 my-2 my-sm-0" (click)="onSignin()">SIGN IN</button>
          </li>
      </ul>
    </span>


  </div>

</nav>