<div class="loading-shade" *ngIf="processing"></div>

<div class="confirm-dialog-card">
    <nb-card>
        <nb-card-body *ngIf="type==_TYPE_DELETE_SELLER">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-12 title-text flex-center">
                        DELETE SELLER
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-12 info-text flex-center" style="font-size:15px">
                        Deleting this seller will remove it and any data collected in setting it up.
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12 flex-center r-top">
                        <button class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onDeleteSeller()">DELETE SELLER</button>
                        <button nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Cancel</button>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12">
                        <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                    </div>
                </div>

            </div>
        </nb-card-body>

        <nb-card-body *ngIf="type==_TYPE_DELETE_AUCTION">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-12 title-text flex-center">
                        DELETE AUCTION
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-12 info-text flex-center" style="font-size:15px">
                        Deleting this auction will remove it and any data collected in setting it up.
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12 flex-center r-top">
                        <button class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onDeleteAuction()">DELETE AUCTION</button>
                        <button nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Cancel</button>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12">
                        <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                    </div>
                </div>

            </div>
        </nb-card-body>

        <nb-card-body *ngIf="type==_TYPE_CLOSE_AUCTION">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-12 title-text flex-center">
                        CLOSE AUCTION
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-12 info-text flex-center" style="font-size:15px">
                        Closing this auction will end all bidding.
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12 flex-center r-top">
                        <button class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onCloseAuction()">CLOSE AUCTION</button>
                        <button nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Cancel</button>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12">
                        <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                    </div>
                </div>

            </div>
        </nb-card-body>

        <nb-card-body *ngIf="type==_TYPE_DIRECT_SETTLEMENT">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-md-12">
                      <span style="float:right">
                          <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                      </span>
                  </div>
              </div>
              <div class="row mb-4">
                  <div class="col-md-12 title-text flex-center">
                      DIRECT SETTLEMENT
                  </div>
              </div>

              <div class="row mb-4">
                  <div class="col-md-12 info-text flex-center" style="font-size:15px">
                      Closing this auction will move the payment status of all sold lots to "Direct Paid" and settle the auction.
                  </div>
              </div>

              <div class="row mb-2">
                  <div class="col-md-12 flex-center r-top">
                      <button class="ml-2" nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Cancel</button>
                      <button nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onDirectSettlement()">DIRECT SETTLEMENT</button>
                  </div>
              </div>

              <div class="row mb-2">
                  <div class="col-md-12">
                      <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                  </div>
              </div>

          </div>
        </nb-card-body>

        <nb-card-body *ngIf="type==_TYPE_SETTLE_AUCTION">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-md-12">
                      <span style="float:right">
                          <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                      </span>
                  </div>
              </div>
              <div class="row mb-4">
                  <div class="col-md-12 title-text flex-center">
                      SETTLING THE AUCTION
                  </div>
              </div>

              <div class="row mb-4">
                  <div class="col-md-12 info-text flex-center" style="font-size:15px">
                      Settling the auction will begin the process of deducting our fees and paying out any remaining proceeds to the seller(s). <br /><br />
                      You will not be able to go back to the Closed status or make any further changes to the auction report.
                  </div>
              </div>

              <div class="row mb-2">
                  <div class="col-md-12 flex-center r-top">
                      <button class="ml-2" nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Cancel</button>
                      <button nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onSettleAuction()">SETTLE AUCTION</button>
                  </div>
              </div>

              <div class="row mb-2">
                  <div class="col-md-12">
                      <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                  </div>
              </div>

          </div>
        </nb-card-body>

        <nb-card-body *ngIf="type==_TYPE_REMOVE_LOT">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-12 title-text flex-center">
                        REMOVE LOT FROM AUCTION
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-12 info-text flex-center" style="font-size:15px">
                        Remove this lot from the auction.
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12 flex-center r-top">
                        <button class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onRemoveLot()">REMOVE LOT</button>
                        <button nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Cancel</button>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12">
                        <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                    </div>
                </div>

            </div>
        </nb-card-body>
        
        <nb-card-body *ngIf="type==_TYPE_SAVE_SELLER">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-12 title-text flex-center">
                        SELLER BUSINESS ACCOUNT
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-12 info-text flex-center" style="font-size:15px">
                        You are saving a seller business account without associating any user accounts.
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12 flex-center r-top">
                        <button class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onSaveSeller()">SAVE</button>
                        <button nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Cancel</button>
                    </div>
                </div>

            </div>
        </nb-card-body>

        <nb-card-body *ngIf="type==_TYPE_UNSAVED_CHANGES">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-md-12 title-text flex-center">
                        UNSAVED CHANGES
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-md-12 info-text flex-center" style="font-size:15px">
                        Select how you would like to proceed.
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12 flex-center r-top">
                        <button class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onContinueWithoutSaving()">CONTINUE WITHOUT SAVING</button>
                        <button *ngIf="editNewIsValid" class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onSaveUnsavedChanges()">SAVE AND CONTINUE</button>
                        <button *ngIf="!editNewIsValid" class="ml-2" nbButton size="small" [nbSpinner]="processing" status="primary" (click)="onClose()">CONTINUE EDITING</button>
                    </div>
                </div>

            </div>
        </nb-card-body>
        

    </nb-card>
</div>

