<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mt-2 mb-4">
        <div class="col-md-12 mb-4">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a routerLink="/dashboard" class="link-text ml-1">Dashboard</a>
        </div> 
    </div> 

    <div class="row mb-3" [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">
        <div class="col-md-6 title-text center" style="font-size:22px">
            Manage Site
        </div>
        <div class="col-md-6 right-justify center">
            <button class="r-top" nbButton size="small" status="primary" (click)="onNewUser()">+ NEW USER</button>
        </div>
    </div>
    
    <div class="row mb-5">
        <ng-container *ngFor="let menu of menuCards">
              <div class="col-md-4 center mt-3">
                  <app-menucard [menu]="menu"></app-menucard>
              </div>
        </ng-container>  
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-6 label-text center" style="font-size:15px;font-weight:600">
            Users
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-md-6 label-text center">
            SEARCH USERS
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-4 label-text center">
          <nb-form-field>
            <nb-icon nbSuffix icon="search-outline" pack="eva" status="primary"></nb-icon>
            <input type="text"
                  nbInput
                  placeholder="Search by First or Last Name"
                  id="searchuser"
                  name="searchuser"
                  [(ngModel)]="searchuser"
                  (ngModelChange)="onUpdateFilter($event)">
          </nb-form-field>
        </div>
    </div>
    
    <div class="row mb-3 mt-5">
        <div class="col-md-6 center">
            <span class="info-text">All Results</span><span class="info-data-text ml-2" style="font-weight:700">{{ getRecordsLength() }}</span>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <app-userslist #myuserslist *ngIf="usersList.length > 0" [rows]="usersList" (messageEvent)="receiveMessage($event)"></app-userslist>
        </div>
    </div>
    

</div> <!-- container-fluid -->
