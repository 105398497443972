import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortalUser } from '../../model/portaluser.model';

@Component({
  selector: 'app-userslist',
  templateUrl: './userslist.component.html',
  styleUrls: ['./userslist.component.scss']
})
export class UserslistComponent implements OnInit {

  @Input() rows:PortalUser[] = [];
  @Output() messageEvent = new EventEmitter<any>();
  
  filteredList:PortalUser[] = [];
  p:number = 1; 
  sortHeader = {
    "element":0,
    "sortDesc":false
  };
  
  constructor() { }

  ngOnInit(): void {
    this.filteredList = this.rows.slice();
    this.onSort(1); 
  }
  
  async filter(filterstr:string) {
    let tempList = this.rows.slice();

    return new Promise(resolve => {

      if(filterstr.length > 0){
        tempList = tempList.filter(function (el) {
          let familyName = el.familyName.toUpperCase();
          let givenName = el.givenName.toUpperCase();
          return (familyName.includes(filterstr.toUpperCase()) || givenName.includes(filterstr.toUpperCase()));
        });
      }

      this.filteredList = tempList.slice();

    });
  }
  
  onSort(element){  
    // console.log('onSort:',element);
    if(this.sortHeader['element'] == element){
      this.sortHeader['sortDesc'] = !this.sortHeader['sortDesc'];
    }
    else {
      this.sortHeader['element'] = element;
      this.sortHeader['sortDesc'] = false;
    }
    this.sortList();
  }
  
  isSortDesc(element){
    return (this.sortHeader['element'] == element && this.sortHeader['sortDesc'])
  }

  isSortAsc(element){
    return (this.sortHeader['element'] == element && !this.sortHeader['sortDesc'])
  }
  
  sortList(){
    let element = this.sortHeader['element'];
    let sortDescending = this.sortHeader['sortDesc'];

    switch(element){
      case 1:
        this.filteredList.sort(sortDescending ? this.compareDescFirstName : this.compareAscFirstName);
        break;
      case 2:
        this.filteredList.sort(sortDescending ? this.compareDescLastName : this.compareAscLastName);
        break;
      default:
        break;
    }
  }
  
  compareAscLastName(a:PortalUser,b:PortalUser){
    let valueA = a.familyName.toUpperCase();
    let valueB = b.familyName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescLastName(a:PortalUser,b:PortalUser){
    let valueA = a.familyName.toUpperCase();
    let valueB = b.familyName.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  compareAscFirstName(a:PortalUser,b:PortalUser){
    let valueA = a.givenName.toUpperCase();
    let valueB = b.givenName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescFirstName(a:PortalUser,b:PortalUser){
    let valueA = a.givenName.toUpperCase();
    let valueB = b.givenName.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }
  
  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }
  

}
