<div *ngFor="let sellerKey of sellerKeys()">
  <h6>{{ groupListing[sellerKey].name }}</h6>
  <div *ngFor="let buyerKey of buyerKeys(sellerKey)">

    <div class="d-flex flex-row justify-content-between align-items-center winning-bidder-container1">

      <div class="d-flex flex-row">
        <div class="winning-bidder-header">Winning Bidder</div>
        <div class="ml-3 winning-bidder-name">{{ groupListing[sellerKey].bidders[buyerKey].name }}</div>
      </div>

      <div class="d-flex flex-row align-items-center">
        <ng-container *ngIf="groupListing[sellerKey].bidders[buyerKey].paymentStatus == 'UNPAID'">
          <ng-container *ngIf="sendInvoiceEnabled(sellerKey, buyerKey); else invoice_actions">
            <button nbButton status="basic" size="small" class="d-flex align-self-center" (click)="onSendInvoice(sellerKey, buyerKey)" [nbSpinner]="isLoading(buyerKey, sellerKey)">{{ loadingText("Send invoice", buyerKey, sellerKey) }}</button>
          </ng-container>
          <ng-template #invoice_actions>
            <div class="invoice-actions-container d-flex" *ngIf="!isAuctionSettled()">
              <span>{{ formatAsMonthYear(groupListing[sellerKey].bidders[buyerKey].invoiceDateSent) }}</span>
              <a class="icon-link" (click)="onSendInvoice(sellerKey, buyerKey)" *ngIf="invoiceNotAutomatedPaidYet(sellerKey, buyerKey)"><nb-icon status="basic" icon="refresh" class="ml-2"></nb-icon></a>
              <a class="icon-link" (click)="onViewInvoice(groupListing[sellerKey].bidders[buyerKey].lineItems[0].invoiceId)"><nb-icon status="basic" icon="eye" class="ml-2"></nb-icon></a>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="invoiceNotAutomatedPaidYet(sellerKey, buyerKey); else automated_paid">
          <nb-select class="ml-3" placeholder="Select Payment Status" [(selected)]="groupListing[sellerKey].bidders[buyerKey].paymentStatus" (selectedChange)="onUpdatePaymentStatus(sellerKey, buyerKey)">
            <nb-option *ngFor="let item of paymentStatusOptions" [value]="item.key" [disabled]="isAuctionSettled()">{{ item.value }}</nb-option>
          </nb-select>
        </ng-container>
        <ng-template #automated_paid>
          <div class="automated-paid-container d-flex ml-3">
            <nb-icon status="basic" icon="done-all-outline"></nb-icon>
            <span>Paid</span>
          </div>
        </ng-template>
      </div>

    </div>

    <ng-container *ngIf="groupListing[sellerKey].bidders[buyerKey].lineItems.length > 0">
      <div class="d-flex flex-row justify-content-between align-items-center winning-bidder-container2">
        <div class="d-flex flex-row">
          <div class="mr-4">{{ groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderPhoneNumber }}</div>
          <div class="mr-4">{{ groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderEmail }}</div>
          <div>{{ getAddress(groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderAddress) }}</div>
          <div class="noaddress-text" *ngIf="!groupListing[sellerKey].bidders[buyerKey].lineItems[0].winningBidderAddress">No Address on File</div>
        </div>
      </div>
    </ng-container>

    <div class="table-responsive winning-bidder-table">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="table-header-text-link" style="min-width: 4rem;">LOT #</th>
            <th scope="col" class="table-header-text-link" style="min-width: 18.75rem;">NAME</th>
            <th scope="col" class="table-header-text-link" style="width: 10rem;">FINAL BID</th>
            <th scope="col" class="table-header-text-link">QTY</th>
            <th scope="col" class="table-header-text-link">FINAL PRICE</th>
            <th scope="col" class="table-header-text-link">ACTION</th>
            <th scope="col" class="table-header-text-link">SALE/NO SALE</th>
          </tr>
        </thead>
        <tbody>
            <tr app-auction-report-lot-detail *ngFor="let item of groupListing[sellerKey].bidders[buyerKey].lineItems" [lot]="item" saleStatus="SOLD" [auctionStatus]="auctionModel.activeAuction.status"></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
