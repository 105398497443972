<div class="container-fluid">

    <div class="row no-gutters">
	    <div class="col-md-3 mt-2 mb-2">
	      <div><span class="table-column-text"> {{ getBidPrice() }} </span></div>
	    </div>
    	
	    <div class="col-md-3 mt-2 mb-2">
	      <div><span class="table-column-text"> {{ bid.bidderFirstName }} {{ bid.bidderLastName }} </span></div>
	    </div>
	    
	    <div class="col-md-2 mt-2 mb-2">
	      <div><span class="table-column-text"> </span></div>
	    </div>

	    <div class="col-md-2 mt-2 mb-2">
	      <div><span class="table-column-text"> {{ bid.bidderId }}</span></div>
	    </div>

	    <div class="col-md-2 mt-2 mb-2">
	      <div><span class="flex-right table-column-time-text"> {{ getTime(bid.bidTime) }}</span></div>
	    </div>

    </div> <!-- header -->
</div>
